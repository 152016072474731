"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var defaultNotification = {
  error: {
    message: null,
    color: '#f44336'
  },
  success: {
    message: 'The Data Access Request has been sent to a System Administrator for review.',
    color: '#5D53F6'
  },
  noAclToRequest: {
    message: 'Your data access request has been submitted. No additional access can be requested.',
    color: '#f44336'
  },
  noAccess: {
    message: 'Please submit a Data Access Request (DAR) to access protected pages.',
    color: '#5D53F6'
  },
  disabled: {
    message: 'Request failed, disabled users are not allowed to submit data access reqeusts.',
    color: '#f44336'
  }
};
var _default = defaultNotification;
exports.default = _default;