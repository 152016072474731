"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "CartContext", {
  enumerable: true,
  get: function get() {
    return _ContextProvider.CartContext;
  }
});
Object.defineProperty(exports, "CartContextProvider", {
  enumerable: true,
  get: function get() {
    return _ContextProvider.default;
  }
});
Object.defineProperty(exports, "cartReducerGenerator", {
  enumerable: true,
  get: function get() {
    return _reducers.cartReducerGenerator;
  }
});
Object.defineProperty(exports, "convertToCSV", {
  enumerable: true,
  get: function get() {
    return _utils.convertToCSV;
  }
});
Object.defineProperty(exports, "createFileName", {
  enumerable: true,
  get: function get() {
    return _utils.createFileName;
  }
});
Object.defineProperty(exports, "downloadJson", {
  enumerable: true,
  get: function get() {
    return _utils.downloadJson;
  }
});
Object.defineProperty(exports, "initCart", {
  enumerable: true,
  get: function get() {
    return _actions.initCart;
  }
});
Object.defineProperty(exports, "onAddCartFiles", {
  enumerable: true,
  get: function get() {
    return _actions.onAddCartFiles;
  }
});
Object.defineProperty(exports, "onCommentChange", {
  enumerable: true,
  get: function get() {
    return _actions2.onCommentChange;
  }
});
Object.defineProperty(exports, "onDeleteAllCartFile", {
  enumerable: true,
  get: function get() {
    return _actions.onDeleteAllCartFile;
  }
});
Object.defineProperty(exports, "onDeleteCartFile", {
  enumerable: true,
  get: function get() {
    return _actions.onDeleteCartFile;
  }
});
Object.defineProperty(exports, "setCartConfig", {
  enumerable: true,
  get: function get() {
    return _actions2.setCartConfig;
  }
});
var _ContextProvider = _interopRequireWildcard(require("./ContextProvider"));
var _reducers = require("./store/reducers");
var _actions = require("./store/actions");
var _actions2 = require("./state/actions");
var _utils = require("./utils");
function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}
function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }
  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }
  var cache = _getRequireWildcardCache(nodeInterop);
  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }
  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }
  newObj.default = obj;
  if (cache) {
    cache.set(obj, newObj);
  }
  return newObj;
}