"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * Review Requests Table styles
 */
var _default = function _default() {
  return {
    customDataTable: {
      '& .MuiTableFooter-root': {
        borderBottom: '3px solid #42779A'
      },
      '& .MuiTableCell-head:first-child, .MuiTableCell-body:first-child': {
        paddingLeft: '37px'
      },
      '& .MuiTableCell-head:last-child': {
        paddingRight: '37px',
        textAlign: 'center'
      },
      '& .MuiTableCell-body:last-child': {
        paddingRight: '37px'
      },
      '& .MuiTableRow-root': {
        height: '54px'
      }
    }
  };
};
exports.default = _default;