"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setCartConfig = exports.onCommentChange = exports.actionTypes = void 0;
var actionTypes = {
  ON_COMMENT_CHANGE: 'ON_COMMENT_CHANGE',
  SET_CART_CONFIG: 'SET_CART_CONFIG'
};
exports.actionTypes = actionTypes;
var onCommentChange = function onCommentChange(text) {
  return {
    type: actionTypes.ON_COMMENT_CHANGE,
    payload: text
  };
};
exports.onCommentChange = onCommentChange;
var setCartConfig = function setCartConfig(config) {
  return {
    type: actionTypes.SET_CART_CONFIG,
    payload: config
  };
};
exports.setCartConfig = setCartConfig;