"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.capitalize = void 0;
/* eslint-disable import/prefer-default-export */
var capitalize = function capitalize(str) {
  return str.substr(0, 1).toUpperCase() + str.substr(1, str.length - 1);
};
exports.capitalize = capitalize;