"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "DEFAULT_COLORS_EVEN", {
  enumerable: true,
  get: function get() {
    return _DonutChartGenerator.DEFAULT_COLORS_EVEN;
  }
});
Object.defineProperty(exports, "DEFAULT_COLORS_ODD", {
  enumerable: true,
  get: function get() {
    return _DonutChartGenerator.DEFAULT_COLORS_ODD;
  }
});
Object.defineProperty(exports, "DEFAULT_CONFIG_DONUT", {
  enumerable: true,
  get: function get() {
    return _DonutChartGenerator.DEFAULT_CONFIG_DONUT;
  }
});
Object.defineProperty(exports, "DEFAULT_CONFIG_SUNBURST", {
  enumerable: true,
  get: function get() {
    return _SunburstChartGenerator.DEFAULT_CONFIG_SUNBURST;
  }
});
Object.defineProperty(exports, "DEFAULT_CONFIG_WIDGET", {
  enumerable: true,
  get: function get() {
    return _WidgetGenerator.DEFAULT_CONFIG_WIDGET;
  }
});
Object.defineProperty(exports, "DonutChartGenerator", {
  enumerable: true,
  get: function get() {
    return _DonutChartGenerator.DonutChartGenerator;
  }
});
Object.defineProperty(exports, "SunburstChartGenerator", {
  enumerable: true,
  get: function get() {
    return _SunburstChartGenerator.SunburstChartGenerator;
  }
});
Object.defineProperty(exports, "WidgetGenerator", {
  enumerable: true,
  get: function get() {
    return _WidgetGenerator.WidgetGenerator;
  }
});
var _DonutChartGenerator = require("./DonutChart/DonutChartGenerator");
var _SunburstChartGenerator = require("./SunburstChart/SunburstChartGenerator");
var _WidgetGenerator = require("./WidgetGenerator");