"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * Returns the value of the "redirect" parameter in the query string of the current URL,
 * or "/" if the "redirect" parameter is not present in the query string.
 *
 * @returns {string} The redirect path as a string.
 */
var getRedirectPath = function getRedirectPath(query) {
  var path = query.get('redirect') || '/';
  return path;
};
var _default = getRedirectPath;
exports.default = _default;