"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/*
 * Default text labels.
 */
var getTextLabels = function getTextLabels() {
  return {
    body: {
      noMatch: 'Sorry, no matching records found',
      toolTip: 'Sort'
    },
    pagination: {
      next: 'Next Page',
      previous: 'Previous Page',
      rowsPerPage: 'Rows per page:',
      displayRows: 'of'
    },
    toolbar: {
      search: 'Search',
      downloadCsv: 'Download CSV',
      print: 'Print',
      viewColumns: 'View Columns',
      filterTable: 'Filter Table'
    },
    filter: {
      all: 'All',
      title: 'FILTERS',
      reset: 'RESET'
    },
    viewColumns: {
      title: 'Show Columns',
      titleAria: 'Show/Hide Table Columns'
    },
    selectedRows: {
      text: 'row(s) selected',
      delete: 'Delete',
      deleteAria: 'Delete Selected Rows'
    }
  };
};
var _default = getTextLabels;
exports.default = _default;