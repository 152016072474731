"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Actions = require("./Actions");
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");
  return typeof key === "symbol" ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (typeof input !== "object" || input === null) return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (typeof res !== "object") return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (hint === "string" ? String : Number)(input);
}
var reducer = function reducer(state, action) {
  var type = action.type,
    payload = action.payload;
  switch (type) {
    case _Actions.actionTypes.ON_COLUMN_SORT:
      return _objectSpread(_objectSpread({}, state), {}, {
        sortOrder: payload.sort,
        sortBy: payload.column
      });
    case _Actions.actionTypes.ON_PAGE_CHANGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        page: payload.pageNumb
      });
    case _Actions.actionTypes.ON_ROWS_PER_PAGE_CHANGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        rowsPerPage: payload.rowsPerPage,
        page: payload.page
      });
    case _Actions.actionTypes.VIEW_COLUMN_CHANGE:
      return _objectSpread(_objectSpread({}, state), payload);
    case _Actions.actionTypes.ON_ROW_SELECT:
      return _objectSpread(_objectSpread({}, state), {}, {
        selectedRows: payload
      });
    case _Actions.actionTypes.ON_PAGE_AND_TOTAL_COUNT_CHANGE:
      return _objectSpread(_objectSpread({}, state), {}, {
        totalRowCount: payload.totalRowCount,
        page: payload.page
      });
    case _Actions.actionTypes.SET_TOTAL_ROW_COUNT:
      return _objectSpread(_objectSpread({}, state), {}, {
        totalRowCount: payload
      });
    default:
      return state;
  }
};
var _default = reducer;
exports.default = _default;