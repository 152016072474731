"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Tooltip = _interopRequireDefault(require("@material-ui/core/Tooltip"));
var _core = require("@material-ui/core");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var ToolTip = (0, _core.withStyles)(function (theme) {
  return {
    tooltip: {
      backgroundColor: function backgroundColor(props) {
        return props.backgroundColor || '#FFFFFF';
      },
      color: function color(props) {
        return props.color || '#1C2023';
      },
      maxWidth: function maxWidth(props) {
        return props.maxWidth || '220px';
      },
      fontSize: function fontSize(props) {
        return props.fontSize || theme.typography.pxToRem(12);
      },
      border: function border(props) {
        return props.border || '2px solid #A7AFB3';
      },
      fontFamily: function fontFamily(props) {
        return props.fontFamily || 'Open Sans';
      },
      fontWeight: function fontWeight(props) {
        return props.fontWeight || '600';
      },
      textAlign: function textAlign(props) {
        return props.textAlign || 'left';
      },
      lineHeight: function lineHeight(props) {
        return props.lineHeight || '1.6';
      },
      padding: function padding(props) {
        return props.padding || '10px 12px 10px 12px';
      },
      borderRadius: function borderRadius(props) {
        return props.borderRadius || '0px';
      }
    },
    arrow: {
      color: function color(props) {
        return props.arrowColor || '#FFFFFF';
      },
      fontSize: function fontSize(props) {
        return props.arrowSize || theme.typography.pxToRem(20);
      },
      '&::before': {
        border: function border(props) {
          return props.arrowBorder || '2px solid #A7AFB3';
        }
      }
    }
  };
})(_Tooltip.default);
var _default = ToolTip;
exports.default = _default;