"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = function _default() {
  return {
    slider_INPUT_MIN: {}
  };
};
exports.default = _default;