"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _getDefaultACL = _interopRequireDefault(require("./getDefaultACL"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var setDefaultValues = function setDefaultValues(formFields, getMyUser, availableArms) {
  return formFields.reduce(function (values, field) {
    var id = field.id,
      type = field.type,
      multiple = field.multiple,
      display = field.display;
    if (!values[id]) {
      // eslint-disable-next-line no-param-reassign
      values[id] = ['dropdown', 'aclDropdown'].includes(type) && !display ? (0, _getDefaultACL.default)(availableArms) : multiple ? [] : getMyUser[id] || '';
    }
    return values;
  }, {});
};
var _default = setDefaultValues;
exports.default = _default;