"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _StatsBarView = _interopRequireDefault(require("./StatsBarView"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
var _default = _StatsBarView.default;
exports.default = _default;