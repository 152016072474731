"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.storeInLocalStorage = exports.getFromLocalStorage = exports.deleteFromLocalStorage = void 0;
var storeInLocalStorage = function storeInLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
};
exports.storeInLocalStorage = storeInLocalStorage;
var getFromLocalStorage = function getFromLocalStorage(key) {
  return JSON.parse(localStorage.getItem(key)) || {};
};
exports.getFromLocalStorage = getFromLocalStorage;
var deleteFromLocalStorage = function deleteFromLocalStorage(key) {
  return localStorage.removeItem(key);
};
exports.deleteFromLocalStorage = deleteFromLocalStorage;