"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dateTimeStamp = dateTimeStamp;
exports.formatBytes = formatBytes;
exports.manipulateLinks = manipulateLinks;
/* eslint-disable no-param-reassign */

function manipulateLinks(tableData) {
  tableData.forEach(function (column, index) {
    if (column.link !== undefined && column.link !== null) {
      var linkKey = column.link.substring(column.link.lastIndexOf('{') + 1, column.link.lastIndexOf('}'));
      var linktext = column.link.split('{')[0];
      if (linktext.startsWith('/')) {
        tableData[index].internalLink = true;
      } else {
        tableData[index].externalLink = true;
      }
      var arrayIndex = tableData.findIndex(function (p) {
        return p.dataField === linkKey;
      });
      tableData[index].actualLink = linktext;
      tableData[index].actualLinkId = arrayIndex;
    }
  });
  return tableData;
}
function dateTimeStamp() {
  var date = new Date();
  var yyyy = date.getFullYear();
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  if (dd < 10) {
    dd = "0".concat(dd);
  }
  if (mm < 10) {
    mm = "0".concat(mm);
  }
  var todaysDate = "".concat(yyyy, "-").concat(mm, "-").concat(dd);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();
  if (hours < 10) {
    hours = "0".concat(hours);
  }
  if (minutes < 10) {
    minutes = "0".concat(minutes);
  }
  if (seconds < 10) {
    seconds = "0".concat(seconds);
  }
  return "_".concat(todaysDate, "_").concat(hours, "-").concat(minutes, "-").concat(seconds);
}
function formatBytes(bytes) {
  var decimals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  if (bytes === 0) return '0 Bytes';
  var dm = decimals < 0 ? 0 : decimals;
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  var i = Math.floor(Math.log(bytes) / Math.log(1024));
  return "".concat(parseFloat((bytes / Math.pow(1024, i)).toFixed(dm)), " ").concat(sizes[i]);
}