"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = function _default() {
  return {
    sectionSummaryText: {
      color: '#323232',
      fontFamily: 'Raleway',
      fontSize: '15px',
      fontWeight: 'bold',
      letterSpacing: '0.25px',
      marginLeft: '15px',
      lineHeight: '26px'
    },
    sortGroup: {
      paddingTop: '10px',
      paddingBottom: '5px',
      borderTop: '1px solid #B1B1B1',
      textAlign: 'left',
      marginLeft: '-5px',
      boxShadow: 'inset -10px 2px 10px -7px rgb(50 50 50 / 25%)'
    },
    sortGroupItem: {
      cursor: 'pointer',
      fontFamily: 'Nunito',
      fontSize: '10px',
      marginRight: '32px'
    }
  };
};
exports.default = _default;