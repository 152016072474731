"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * Manage Access Table styles
 */
var _default = function _default() {
  return {
    btn: {
      backgroundColor: '#437BBE',
      color: '#fff',
      borderRadius: '10px',
      marginLeft: '-6px'
    },
    link: {
      fontWeight: 'bold',
      textDecoration: 'underline'
    }
  };
};
exports.default = _default;