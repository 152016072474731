"use strict";

var _toConsumableArray = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sortType = exports.sortBySection = exports.sortByCheckedItem = exports.getNumberArray = void 0;
/* eslint-disable camelcase */
var sortType = {
  ALPHABET: 'ALPHABET',
  NUMERIC: 'NUMERIC',
  ALPHA_NUMERIC: 'ALPHA_NUMERIC',
  CUSTOM_NUMBER: 'CUSTOM_NUMBER',
  RANGE: 'RANGE'
};

/**
 * Sort checkboxes by Checked
 *
 * @param {array} checkboxData
 * @return {array}
 */
exports.sortType = sortType;
var sortByCheckedItem = function sortByCheckedItem(sortfacetValues) {
  return sortfacetValues.sort(function (a, b) {
    return b.isChecked - a.isChecked;
  });
};
exports.sortByCheckedItem = sortByCheckedItem;
var startIndex = function startIndex(range) {
  return Number("".concat(range).split('-')[0]);
};
var lowerCaseString = function lowerCaseString(text) {
  return "".concat(text).toLowerCase();
};

/**
 * return numbers array from string
 */
var getNumberArray = function getNumberArray(range) {
  var numberArray = "".concat(range).match(/\d+/g) || [];
  // return inf if there is no number in string
  if (numberArray.length === 0) {
    return [Infinity];
  }
  return numberArray;
};
/**
 * Sort checkboxes by single section
 * @param {array} checkboxData
 * @return {array}
 */
exports.getNumberArray = getNumberArray;
var sortBySection = function sortBySection(_ref) {
  var facetValues = _ref.facetValues,
    sort_type = _ref.sort_type,
    sortBy = _ref.sortBy;
  var sortfacetValues = _toConsumableArray(facetValues);
  if (!sortfacetValues) {
    return facetValues;
  }
  if (sortBy === sortType.NUMERIC) {
    sortfacetValues.sort(function (a, b) {
      return b.subjects - a.subjects;
    });
  } else {
    sortfacetValues.sort(function (a, b) {
      return lowerCaseString(a.name) > lowerCaseString(b.name) || -(lowerCaseString(a.name) < lowerCaseString(b.name));
    });
  }
  if (sort_type === sortType.CUSTOM_NUMBER && sortBy !== sortType.NUMERIC) {
    sortfacetValues.sort(function (a, b) {
      var aNumbs = getNumberArray(a.name);
      var bNumbs = getNumberArray(b.name);
      var aNumb = Number(aNumbs[0]);
      var bNumb = Number(bNumbs[0]);
      return aNumbs.length === bNumbs.length ? aNumb > bNumb : -(aNumb <= bNumb);
    });
  }
  if (sort_type === sortType.RANGE && sortBy !== sortType.NUMERIC) {
    sortfacetValues.sort(function (a, b) {
      return startIndex(a.name) > startIndex(b.name) || -(startIndex(a.name) < startIndex(b.name));
    });
  }
  /**
   * Display checked item always on top
   */
  var sortedValues = sortByCheckedItem(_toConsumableArray(sortfacetValues));
  return sortedValues;
};
exports.sortBySection = sortBySection;