"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * Pending Requests Table styles
 */
var _default = function _default() {
  return {
    btn: {
      backgroundColor: '#7E4EC5',
      color: '#fff'
    },
    link: {
      fontWeight: 'bold',
      textDecoration: 'underline'
    }
  };
};
exports.default = _default;