"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.DEFAULT_CONFIG_REVIEW_REQUESTS_TABLE = void 0;
/* eslint-disable no-unused-vars */
/**
 * Default configuration for Review Requests Table
 */
var DEFAULT_CONFIG_REVIEW_REQUESTS_TABLE = {
  // Misc. Configuration Options
  config: {},
  // Helper functions used by the component
  functions: {
    /**
     * Details will be added soon.
     *
     */
    getOptions: function getOptions() {},
    /**
     * Details will be added soon.
     *
     */
    getDefaultCustomFooter: function getDefaultCustomFooter() {},
    /**
     * Details will be added soon.
     *
     */
    CustomDataTable: function CustomDataTable() {}
  }
};
exports.DEFAULT_CONFIG_REVIEW_REQUESTS_TABLE = DEFAULT_CONFIG_REVIEW_REQUESTS_TABLE;
var _default = DEFAULT_CONFIG_REVIEW_REQUESTS_TABLE;
exports.default = _default;