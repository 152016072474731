const COLORS_EVEN = [
  '#057ebd',
  '#f78f48',
  '#CBDEEE',
  '#79287c',
  '#0e3151',
  '#7dc242',
];

// const COLORS_ODD = [
//   '#a593cd',
//   '#f78f48',
//   '#79287c',
//   '#0e3151',
//   '#057ebd',
//   '#7dc242',
// ];

const colors = {
  odd: COLORS_EVEN,
  even: COLORS_EVEN,
};

export default colors;
