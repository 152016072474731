"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateUploadMetadata = exports.updateUploadData = exports.updateAutocompleteData = exports.resetUploadData = exports.resetAllData = void 0;
var _ActionTypes = require("./ActionTypes");
var updateAutocompleteData = function updateAutocompleteData(data) {
  return {
    type: _ActionTypes.ActionTypes.UPDATE_LOCALFIND_AUTOCOMPLETE_DATA,
    payload: data
  };
};
exports.updateAutocompleteData = updateAutocompleteData;
var updateUploadData = function updateUploadData(data) {
  return {
    type: _ActionTypes.ActionTypes.UPDATE_LOCALFIND_UPLOAD_DATA,
    payload: data
  };
};
exports.updateUploadData = updateUploadData;
var updateUploadMetadata = function updateUploadMetadata(data) {
  return {
    type: _ActionTypes.ActionTypes.UPDATE_LOCALFIND_UPLOAD_METADATA,
    payload: data
  };
};
exports.updateUploadMetadata = updateUploadMetadata;
var resetUploadData = function resetUploadData() {
  return {
    type: _ActionTypes.ActionTypes.RESET_LOCALFIND_UPLOAD_DATA
  };
};
exports.resetUploadData = resetUploadData;
var resetAllData = function resetAllData() {
  return {
    type: _ActionTypes.ActionTypes.RESET_LOCALFIND_ALL_DATA
  };
};
exports.resetAllData = resetAllData;