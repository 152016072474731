"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatBytes = formatBytes;
/* eslint-disable import/prefer-default-export */
function formatBytes(bytes) {
  var decimals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  if (bytes === 0) return '0 Bytes';
  var dm = decimals < 0 ? 0 : decimals;
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  var i = Math.floor(Math.log(bytes) / Math.log(1024));
  return "".concat(parseFloat((bytes / Math.pow(1024, i)).toFixed(dm)), " ").concat(sizes[i]);
}