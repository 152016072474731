"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "NavBar", {
  enumerable: true,
  get: function get() {
    return _NavBarView.default;
  }
});
var _NavBarView = _interopRequireDefault(require("./NavBarView"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}