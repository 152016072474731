"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ActionTypes = void 0;
var ActionTypes = {
  UPDATE_LOCALFIND_AUTOCOMPLETE_DATA: 'UPDATE_LOCALFIND_AUTOCOMPLETE_DATA',
  UPDATE_LOCALFIND_UPLOAD_DATA: 'UPDATE_LOCALFIND_UPLOAD_DATA',
  UPDATE_LOCALFIND_UPLOAD_METADATA: 'UPDATE_LOCALFIND_UPLOAD_METADATA',
  RESET_LOCALFIND_UPLOAD_DATA: 'RESET_LOCALFIND_UPLOAD_DATA',
  RESET_LOCALFIND_ALL_DATA: 'RESET_LOCALFIND_ALL_DATA'
};
exports.ActionTypes = ActionTypes;
var _default = ActionTypes;
exports.default = _default;