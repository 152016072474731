"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var getAvailableArms = function getAvailableArms(currentACL, listOfArms) {
  var unavailableArmsStatus = ['approved', 'pending'];
  var unavailableArms = Object.keys(currentACL).reduce(function (previousArms, key) {
    var armObject = currentACL[key];
    var resultArray = previousArms;
    if (unavailableArmsStatus.includes(armObject.accessStatus.toLowerCase())) resultArray.push(armObject.armID);
    return resultArray;
  }, []);
  var availableArms = listOfArms.filter(function (arm) {
    return !unavailableArms.includes(arm.id);
  });
  return availableArms;
};
var _default = getAvailableArms;
exports.default = _default;