"use strict";

var _toConsumableArray = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cartReducer = void 0;
exports.cartReducerGenerator = cartReducerGenerator;
exports.filterOutIDs = void 0;
var _actions = require("./actions");
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");
  return typeof key === "symbol" ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (typeof input !== "object" || input === null) return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (typeof res !== "object") return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (hint === "string" ? String : Number)(input);
}
/**
* initialize cart state
* 1. files ids in cart are stored on localstore
* 2. clear cache will remove all the files from cart
*/
var initCartState = function initCartState() {
  if (localStorage.getItem('CartFileIds')) {
    var initState = {};
    initState.filesId = JSON.parse(localStorage.getItem('CartFileIds')) || [];
    return initState;
  }
  return {
    filesId: []
  };
};
var filterOutIDs = function filterOutIDs() {
  var targetIds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var existingIds = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  if (!targetIds || targetIds.length === 0) return existingIds;
  return existingIds.filter(function (id) {
    return !targetIds.includes(id);
  });
};
exports.filterOutIDs = filterOutIDs;
var addFilesToCart = function addFilesToCart(state) {
  var payload = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var distinctIds = filterOutIDs(state.filesId, payload) || [];
  // remove duplicated subject's id
  var uniqueFileIds = payload.length > 0 ? Array.from(new Set(state.filesId.concat(payload))) : state.filesId;
  localStorage.setItem('CartFileIds', JSON.stringify(uniqueFileIds) || []);
  return {
    filesId: _toConsumableArray(uniqueFileIds),
    count: distinctIds.length
  };
};
var getFilesAfterDel = function getFilesAfterDel(state, payload) {
  var filesId = state.filesId;
  var fileIdsAfterDeletion = filesId.filter(function (file) {
    return file !== payload;
  });
  localStorage.setItem('CartFileIds', JSON.stringify(fileIdsAfterDeletion));
  return {
    filesId: fileIdsAfterDeletion,
    count: fileIdsAfterDeletion.length
  };
};
var cartReducer = function cartReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initCartState();
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var payload = action.payload,
    type = action.type;
  switch (type) {
    case _actions.actionTypes.ADD_CART_FILES:
      return _objectSpread(_objectSpread({}, state), addFilesToCart(state, payload));
    case _actions.actionTypes.DELETE_CART_FILE:
      return _objectSpread(_objectSpread({}, state), getFilesAfterDel(state, payload));
    case _actions.actionTypes.DELETE_ALL_CART_FILES:
      // clear all local storage if we remove all record
      localStorage.clear();
      return _objectSpread(_objectSpread({}, state), {}, {
        filesId: []
      });
    default:
      return state;
  }
};
exports.cartReducer = cartReducer;
function cartReducerGenerator() {
  return {
    actionTypes: _actions.actionTypes,
    cartReducer: cartReducer
  };
}