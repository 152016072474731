"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.onToggleStateUpdate = exports.onClearSliderSection = exports.onClearFacetSection = void 0;
exports.sideBarReducerGenerator = sideBarReducerGenerator;
exports.updateSiderValue = void 0;
var _ActionTypes = require("../actions/ActionTypes");
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");
  return typeof key === "symbol" ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (typeof input !== "object" || input === null) return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (typeof res !== "object") return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (hint === "string" ? String : Number)(input);
}
var initFilterState = {};
var onToggleStateUpdate = function onToggleStateUpdate(_ref) {
  var filterState = _ref.filterState,
    datafield = _ref.datafield,
    isChecked = _ref.isChecked,
    name = _ref.name;
  var updatedState = _objectSpread({}, filterState);
  if (!updatedState[datafield]) {
    updatedState[datafield] = {};
  }
  if (isChecked) {
    updatedState[datafield][name] = isChecked;
  } else {
    delete updatedState[datafield][name];
  }
  return updatedState;
};
exports.onToggleStateUpdate = onToggleStateUpdate;
var onClearFacetSection = function onClearFacetSection(_ref2) {
  var filterState = _ref2.filterState,
    facetSection = _ref2.facetSection;
  var updatedState = _objectSpread({}, filterState);
  var datafield = facetSection.datafield;
  if (updatedState[datafield]) {
    updatedState[datafield] = {};
  }
  return updatedState;
};
exports.onClearFacetSection = onClearFacetSection;
var onClearSliderSection = function onClearSliderSection(_ref3) {
  var filterState = _ref3.filterState,
    facetSection = _ref3.facetSection;
  var updatedState = _objectSpread({}, filterState);
  var datafield = facetSection.datafield;
  if (updatedState[datafield]) {
    delete updatedState[datafield];
  }
  return updatedState;
};
exports.onClearSliderSection = onClearSliderSection;
var updateSiderValue = function updateSiderValue(_ref4) {
  var datafield = _ref4.datafield,
    sliderValue = _ref4.sliderValue,
    filterState = _ref4.filterState;
  var updatedState = _objectSpread({}, filterState);
  updatedState[datafield] = sliderValue;
  return updatedState;
};
exports.updateSiderValue = updateSiderValue;
function sideBarReducerGenerator() {
  return {
    actionTypes: _ActionTypes.sideBarActionTypes,
    statusReducer: function statusReducer() {
      var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initFilterState;
      var action = arguments.length > 1 ? arguments[1] : undefined;
      var payload = action.payload,
        type = action.type;
      var updateState;
      switch (type) {
        case _ActionTypes.sideBarActionTypes.FACET_VALUE_CHANGED:
          updateState = onToggleStateUpdate(_objectSpread(_objectSpread({}, state), payload));
          return _objectSpread(_objectSpread({}, state), {}, {
            filterState: _objectSpread({}, updateState)
          });
        case _ActionTypes.sideBarActionTypes.ON_TOGGLE_SLIDER:
          updateState = updateSiderValue(_objectSpread(_objectSpread({}, state), payload));
          return _objectSpread(_objectSpread({}, state), {}, {
            filterState: _objectSpread({}, updateState)
          });
        case _ActionTypes.sideBarActionTypes.CLEAR_ALL_FILTERS:
          return _objectSpread(_objectSpread({}, state), {}, {
            filterState: {}
          });
        case _ActionTypes.sideBarActionTypes.CLEAR_FACET_SECTION:
          updateState = onClearFacetSection(_objectSpread(_objectSpread({}, payload), state));
          return _objectSpread(_objectSpread({}, state), {}, {
            filterState: _objectSpread({}, updateState)
          });
        case _ActionTypes.sideBarActionTypes.CLEAR_SLIDER_SECTION:
          updateState = onClearSliderSection(_objectSpread(_objectSpread({}, payload), state));
          return _objectSpread(_objectSpread({}, state), {}, {
            filterState: _objectSpread({}, updateState)
          });
        case _ActionTypes.sideBarActionTypes.CLEAR_AND_SELECT_FACET_VALUE:
          return {
            filterState: payload
          };
        default:
          return state;
      }
    }
  };
}