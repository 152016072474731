"use strict";

var _defineProperty2 = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");
var _toConsumableArray = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.COLORS_LEVEL_2 = exports.COLORS_LEVEL_1 = void 0;
exports.customCheckBox = customCheckBox;
exports.customSort = customSort;
exports.customSorting = customSorting;
exports.getCheckBoxData = exports.filterData = void 0;
exports.getDonutDataFromDashboardData = getDonutDataFromDashboardData;
exports.getFilters = getFilters;
exports.getStatDataFromDashboardData = getStatDataFromDashboardData;
exports.getSunburstDataFromDashboardData = getSunburstDataFromDashboardData;
exports.isNumeric = isNumeric;
exports.setSelectedFilterValues = setSelectedFilterValues;
exports.setSelectedVlauesToTrue = setSelectedVlauesToTrue;
exports.sortPreference = sortPreference;
exports.transformAPIDataIntoCheckBoxData = transformAPIDataIntoCheckBoxData;
exports.transformInitialDataForSunburst = transformInitialDataForSunburst;
exports.unselectFilters = void 0;
exports.updateCheckBox = updateCheckBox;
exports.updateCurrentSelection = updateCurrentSelection;
var _uuid = require("uuid");
var _lodash = require("lodash");
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");
  return typeof key === "symbol" ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (typeof input !== "object" || input === null) return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (typeof res !== "object") return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (hint === "string" ? String : Number)(input);
}
var COLORS_LEVEL_1 = ['#D4D4D4', '#057EBD', '#0C3151', '#F78F49', '#79287C', '#7CC242', '#61479D'];
exports.COLORS_LEVEL_1 = COLORS_LEVEL_1;
var COLORS_LEVEL_2 = ['#F78F49', '#79287C', '#7CC242', '#61479D', '#D4D4D4', '#057EBD', '#0C3151'];
exports.COLORS_LEVEL_2 = COLORS_LEVEL_2;
var NOT_PROVIDED = 'Not Specified';
/*
 Group : GroupName that will show on the page as category
 field: API return field name
    eg: {
          gender : male
          cases: 123
        }
        gender is the field
  api : API that we are using to get data.
  dtatfield: datatable field that related
  show: control show this category on the page or not

*/
var unselectFilters = function unselectFilters(filtersObj) {
  return filtersObj.map(function (filterElement) {
    return {
      groupName: filterElement.groupName,
      name: filterElement.name,
      datafield: filterElement.datafield,
      isChecked: false
    };
  });
};

// eslint-disable-next-line consistent-return
exports.unselectFilters = unselectFilters;
function customizer(objValue, srcValue) {
  if ((0, _lodash.isArray)(objValue)) {
    return objValue.concat(srcValue);
  }
}

/**
 * Get stat data from dashboard data
 *
 * @param {object} dashboardData
 * @param {object} stats
 * @return {json}
 */

function getStatDataFromDashboardData(dashboardData, stats) {
  var statsWithArraySubj = dashboardData.reduce(function (acc, subjectRow) {
    var calculatedStats = stats.map(function (stat) {
      if (stat.type === 'field') {
        return _defineProperty2({}, stat.statAPI, [].concat(_toConsumableArray(acc[stat.statAPI] || []), [subjectRow[stat.datatable_field]]));
      }
      if (stat.type === 'array') {
        return _defineProperty2({}, stat.statAPI, [].concat(_toConsumableArray(acc[stat.statAPI] || []), _toConsumableArray(subjectRow[[stat.datatable_field]] ? subjectRow[[stat.datatable_field]] : [])));
      }
      if (stat.type === 'object') {
        var statObj = (subjectRow[stat.datatable_field] ? subjectRow[stat.datatable_field] : []).map(function (f) {
          return f[stat.datatable_sub_field];
        });
        return _defineProperty2({}, stat.statAPI, [].concat(_toConsumableArray(acc[stat.statAPI] || []), _toConsumableArray(statObj)));
      }
      return _defineProperty2({}, stat.statAPI, []);
    });
    return (0, _lodash.mergeWith)(acc, calculatedStats, customizer);
  }, {});
  var output = {};
  stats.forEach(function (stat, index) {
    output[stat.statAPI] = _toConsumableArray(new Set(statsWithArraySubj[index][stat.statAPI])).length;
  });
  return output;
}

/**
 * Get Studies program widget from datatable
 *
 * @param {object} data
 * @param {object} level1
 * @param {object} level2
 * @return {json}
 */

function getSunburstDataFromDashboardData(data, level1, level2) {
  // construct data tree
  var widgetData = [];
  var colorIndex = 0;
  data.forEach(function (d) {
    var existLevel1 = false;
    var existLevel2 = false;
    widgetData.map(function (p) {
      if (p.title === d[level1]) {
        // program exist
        existLevel1 = true;
        // eslint-disable-next-line no-param-reassign
        p.caseSize += 1;
        p.children.map(function (study) {
          var s = study;
          if (s.title === "".concat(d[level1], " : ").concat(d[level2])) {
            // study exist
            existLevel2 = true;
            s.size += 1;
            s.caseSize += 1;
          }
          return s;
        }); // end find study
        if (!existLevel2) {
          // new study
          colorIndex += 1;
          p.children.push({
            title: "".concat(d[level1], " : ").concat(d[level2]),
            color: COLORS_LEVEL_2[parseInt(colorIndex, 10)],
            size: 1,
            caseSize: 1
          });
        }
      }
      return p;
    }); // end find program

    if (!existLevel1 && !existLevel2) {
      colorIndex += 1;
      widgetData.push({
        title: d[level1],
        color: COLORS_LEVEL_1[parseInt(colorIndex, 10)],
        caseSize: 1,
        children: [{
          title: "".concat(d[level1], " : ").concat(d[level2]),
          color: COLORS_LEVEL_2[parseInt(colorIndex, 10)],
          size: 1,
          caseSize: 1
        }]
      });
    }
  }); // end foreach

  return {
    key: (0, _uuid.v4)(),
    title: 'root',
    color: COLORS_LEVEL_1[parseInt(colorIndex, 10)],
    children: widgetData
  };
}

/**
 * Get widegt data from datatable
 *
 * @param {object} data
 * @param {string} widgetName
 * @return {json}
 */

function getDonutDataFromDashboardData(data, widgetName) {
  var output = [];
  data.reduce(function (accumulator, currentValue) {
    var targetAttrs = currentValue[widgetName.toString()];
    if (!(currentValue[widgetName.toString()] instanceof Array)) {
      // if currentValue[widgetName.toString() is not an array , convert it into array
      // instead of duplicate code to handle on object type "string" and "array",
      // convert them all into array.
      targetAttrs = [targetAttrs];
    }
    targetAttrs.forEach(function (targetAttr) {
      if (accumulator.has(targetAttr)) {
        accumulator.set(targetAttr, accumulator.get(targetAttr).concat(currentValue.subject_id));
      } else {
        accumulator.set(targetAttr, [currentValue.subject_id]);
      }
    });
    return accumulator;
  }, new Map()).forEach(function (value, key) {
    output.push({
      group: key,
      subjects: _toConsumableArray(new Set(value)).length
    });
  });
  return output;
}

/* filterData function evaluates a row of data with filters,
      to check if this row will be showed in the data table.

     If there is no filter, then display this row.
     If has filters and for each group of filters, at least has one filter option
     is related to the data.
     Otherwise:  Hide this row.
  */
var filterData = function filterData(row, filters) {
  // No filter
  if (filters.length === 0) {
    return true;
  }
  // has filters
  var groups = {};
  filters.forEach(function (filter) {
    if (groups[filter.groupName] && groups[filter.groupName] === true) {
      // do nothing
    } else if (row[filter.datafield]) {
      // check if data has this attribute
      // array includes
      var fName = filter.name === NOT_PROVIDED ? '' : filter.name;
      if (Array.isArray(row[filter.datafield])) {
        if (row[filter.datafield].includes(fName)) {
          groups[filter.groupName] = true;
        } else {
          groups[filter.groupName] = false;
        }
      } else if (row[filter.datafield].toString() === fName) {
        groups[filter.groupName] = true;
      } else {
        groups[filter.groupName] = false;
      }
    } else if (filter.name === NOT_PROVIDED) {
      groups[filter.groupName] = true;
    } else {
      groups[filter.groupName] = false;
    }
  });
  if (Object.values(groups).includes(false)) {
    return false;
  }
  return true;
};

/**
 * Get Filters
 *
 * @param {object} orginFilter
 * @param {object} newCheckBoxs
 * @return {json}
 */
exports.filterData = filterData;
function getFilters(orginFilter, newCheckBoxs) {
  var ogFilter = orginFilter;
  newCheckBoxs.forEach(function (checkbox) {
    var isExist = false;
    ogFilter = ogFilter.filter(function (filter) {
      if (checkbox.groupName === filter.groupName && checkbox.name === filter.name) {
        isExist = true;
        return checkbox.isChecked;
      }
      return true;
    });
    if (!isExist && checkbox.isChecked) {
      ogFilter.push(checkbox);
    }
  });
  return ogFilter;
}
function isNumeric(value) {
  return /^-?\d+$/.test(value);
}

/**
 * customSorting
 *
 * @param {array} a
 * @param {array} b
 * @param {int} i
 * @return {json}
 */

function customSorting(a, b) {
  var i = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  if (b[i] && !a[i]) {
    return -1;
  }
  if (!b[i] && a[i]) {
    return 1;
  }
  if (isNumeric(b[i]) && isNumeric(a[i])) {
    if (b[i] === a[i]) {
      return customSorting(a, b, i + 1);
    }
    return parseInt(a, 10) - parseInt(b, 10);
  }
  if (b[i] > a[i]) {
    return -1;
  }
  if (b[i] < a[i]) {
    return 1;
  }
  if (b[i] === a[i]) {
    if (b[i] && a[i]) {
      return customSorting(a, b, i + 1);
    }
    return 0;
  }
  return -1;
}

/**
 * Everytime the checkbox has been clicked, will call this function to update the data of checkbox
 *
 * @param {object} data
 * @param {object} allCheckBoxs
 * @param {object} activeCheckBoxs
 * @param {object} filters
 * @return {json}
 */

var getCheckBoxData = function getCheckBoxData(data, allCheckBoxs, activeCheckBoxs, filters) {
  return (
    // deepc copy data of orignal checkbox
    JSON.parse(JSON.stringify(allCheckBoxs)).map(function (ck) {
      var checkbox = ck;
      // For current working category, we only update the checkbox data check status.
      // number of cases and sorting order will remain the same.
      if (checkbox.groupName === activeCheckBoxs.groupName) {
        // deep copy current working cate's checkboxs.
        checkbox.checkboxItems = JSON.parse(JSON.stringify(activeCheckBoxs.checkboxItems));
        // update the checkbox items' status
        checkbox.checkboxItems = checkbox.checkboxItems.map(function (el) {
          // for each item , update check status.
          var item = el;
          item.isChecked = false;
          filters.forEach(function (filter) {
            if (item.name === filter.name) {
              item.isChecked = filter.isChecked;
            }
          });
          return item;
        });
      } else {
        // For category that are hidden,
        // number of cases and sorting order will change.
        checkbox.checkboxItems = checkbox.checkboxItems.map(function (el) {
          var item = el;

          // init item's value of number of cases to zero
          item.subjects = [];
          // get filters that are not in this checkbox group
          var filtersNotInThisCheckboxGroup = filters.filter(function (f) {
            return f.groupName !== checkbox.groupName;
          });
          // filter the data
          var subData = data.filter(function (d) {
            return filterData(d, filtersNotInThisCheckboxGroup);
          });

          // Calcuate number of cases
          subData.forEach(function (d) {
            var fName = item.name === NOT_PROVIDED ? '' : item.name;
            if (d[checkbox.datafield]) {
              // value in the array
              if (Array.isArray(d[checkbox.datafield])) {
                if (d[checkbox.datafield].includes(fName)) {
                  item.subjects.push(d.subject_id);
                }
              }
              // Str compare
              if (d[checkbox.datafield] === fName) {
                item.subjects.push(d.subject_id);
              }
            } else if (item.name === NOT_PROVIDED) {
              // No such attribute
              item.subjects.push(d.subject_id);
            }
          });
          item.subjects = _toConsumableArray(new Set(item.subjects)).length;
          // update check status
          item.isChecked = false;
          filters.forEach(function (filter) {
            if (checkbox.groupName === filter.groupName && item.name === filter.name) {
              item.isChecked = filter.isChecked;
            }
          });
          return item;
        }).sort(function (a, b) {
          return customSorting(a.name, b.name);
        });
      }
      return checkbox;
    })
  );
};

/**
 * Transform initial data for sunburst
 *
 * @param {object} data
 * @param {string} level1
 * @param {string} level2
 * @param {string} level1Children
 * @param {array} level1Colors
 *  * @param {array} leve2Colors
 * @return {json}
 */
exports.getCheckBoxData = getCheckBoxData;
function transformInitialDataForSunburst(data) {
  var level1 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'program';
  var level2 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'arm';
  var level1Children = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'children';
  var level1Colors = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : COLORS_LEVEL_1;
  var level2Colors = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : COLORS_LEVEL_2;
  var output = {};
  output.key = (0, _uuid.v4)();
  output.title = 'root';
  output.color = level1Colors[parseInt(1, 10)];
  output.children = data.map(function (level1Child, index) {
    return {
      title: level1Child[level1],
      color: level1Colors[parseInt(index, 10)],
      caseSize: level1Child.caseSize,
      children: level1Child[level1Children].map(function (level2Child, index2) {
        return {
          title: "".concat(level1Child[level1], " : ").concat(level2Child[level2]),
          color: level2Colors[parseInt(index2, 10)],
          caseSize: level2Child.caseSize,
          size: level2Child.caseSize
        };
      })
    };
  });
  return output;
}

// function sortPreference(str) { return { '<'1, '<='2, '>='3, '>': 4 };
function sortPreference(str) {
  if (/^<.*$/.test(str)) {
    return 1;
  }
  if (/^<=.*$/.test(str)) {
    return 2;
  }
  if (/^\(\d,\d]$/.test(str)) {
    return 3;
  }
  if (/^\[\d,\d\]$/.test(str)) {
    return 4;
  }
  if (/^\[\d,\d\)/.test(str)) {
    return 5;
  }
  if (/^\(\d,\d\)/.test(str)) {
    return 6;
  }
  if (/^>=.*$/.test(str)) {
    return 7;
  }
  if (/^>.*$/.test(str)) {
    return 8;
  }
  return 9;
}
function customSort(checkboxData) {
  // eslint-disable-next-line
  checkboxData.map(function (rec) {
    return rec.numberInGroup = rec.name.match(/\d+/g) === null ? null : rec.name.match(/\d+/g).map(Number);
  });
  checkboxData.sort(function (a, b) {
    var amin = a.numberInGroup !== null ? Math.min.apply(Math, _toConsumableArray(a.numberInGroup)) : 'string';
    var bmin = b.numberInGroup !== null ? Math.min.apply(Math, _toConsumableArray(b.numberInGroup)) : 'string';
    if (amin === bmin) {
      if (amin === 'string' && bmin === 'string') {
        var aName = a.name.toLowerCase();
        var bName = b.name.toLowerCase();
        return aName < bName ? -1 : aName > bName ? 1 : 0;
      }
      var anameSortPref = sortPreference(a.name);
      var bnameSortPref = sortPreference(b.name);
      return anameSortPref < bnameSortPref ? -1 : anameSortPref > bnameSortPref ? 1 : 0;
    }
    return amin < bmin ? -1 : amin > bmin ? 1 : 0;
  });
  return checkboxData;
}

/**
 * Transform API Data into CheckBox Data
 *
 * @param {object} data
 * @param {object} field
 * @param {string} caseCountField
 * @return {json}
 */
function transformAPIDataIntoCheckBoxData(data, field) {
  var caseCountField = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'subjects';
  var customNumberSort = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var result = [];
  var preElementIndex = 0;
  data.map(function (el) {
    return {
      name: el[field.toString()] === '' || !el[field.toString()] ? NOT_PROVIDED : el[field.toString()],
      isChecked: false,
      subjects: el[caseCountField]
    };
  }).forEach(function (el) {
    // reduce the duplication
    if (result[parseInt(preElementIndex, 10)] && result[parseInt(preElementIndex, 10)].name) {
      if (result[parseInt(preElementIndex, 10)].name === el.name) {
        result[parseInt(preElementIndex, 10)].subjects += el[caseCountField];
      } else {
        preElementIndex += 1;
        result.push(el);
      }
    } else {
      result.push(el);
    }
  });
  // Sorting based on Filter Intm name
  var sortBasedOnItemName = result.slice(0).sort(function (obj1, obj2) {
    var x = obj1.name.toLowerCase();
    var y = obj2.name.toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
  });
  return customNumberSort ? customSort(sortBasedOnItemName) : sortBasedOnItemName;
}

/**
 *  CustomCheckBox works for first time init Checkbox,
that function transforms the data which returns from API into a another format
so it contains more information and easy for front-end to show it correctly.
 *  * @param {object} currentGroupCount
 *  * @param {object} willUpdateGroupCount
 * * @param {string} caseCountField
 * @return {json}
 */
function customCheckBox(data, facetSearchData1) {
  var caseCountField = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'subjects';
  return facetSearchData1.map(function (mapping) {
    return {
      groupName: mapping.label,
      checkboxItems: transformAPIDataIntoCheckBoxData(data[mapping.api], mapping.field, caseCountField, mapping.customNumberSort),
      datafield: mapping.datafield,
      show: mapping.show,
      section: mapping.section,
      tooltip: mapping.tooltip
    };
  });
}

/**
 * Sets the active filters checkboxes isChecked to true .
 *
 * @param {object} checkboxData
 *  * @param {object} Filters
 * @return {json}
 */

function updateCurrentSelection(checkboxGroup, Filters) {
  var result = checkboxGroup.checkboxItems.map(function (checkboxItem) {
    if (checkboxItem.name === Filters.name) {
      return _objectSpread(_objectSpread({}, checkboxItem), {}, {
        isChecked: Filters.isChecked
      });
    }
    return checkboxItem;
  });
  return _objectSpread(_objectSpread({}, checkboxGroup), {}, {
    checkboxItems: result
  });
}

/**
 *  Updates the checkboxes subject counts from newly recieved API data.
 *  Doesn't updated the recent selected group
 *  * @param {object} currentGroupCount
 *  * @param {object} willUpdateGroupCount
 * * @param {object} currentCheckboxSelection
 * * @param {object} facetSearchData
 * @return {json}
 */

function updateCheckBox(currentGroupCount, willUpdateGroupCount, currentCheckboxSelection, facetSearchData) {
  return facetSearchData.map(function (mapping) {
    if (mapping.label === currentCheckboxSelection.groupName && currentCheckboxSelection.isChecked) {
      var currentGroup = currentGroupCount.filter(function (data) {
        return data.groupName === currentCheckboxSelection.groupName;
      })[0];
      return updateCurrentSelection(currentGroup, currentCheckboxSelection);
    }
    return {
      groupName: mapping.label,
      checkboxItems: transformAPIDataIntoCheckBoxData(willUpdateGroupCount[mapping.api], mapping.field),
      datafield: mapping.datafield,
      show: mapping.show,
      section: mapping.section
    };
  });
}

/**
 * Sets the active filters  group checkboxes  isChecked to true .
 *
 * @param {object} checkboxData
 *  * @param {array} filters
 * @return {json}
 */

function setSelectedVlauesToTrue(checkboxItems, filters) {
  var result = checkboxItems.map(function (checkboxItem) {
    if (filters.includes(checkboxItem.name)) return _objectSpread(_objectSpread({}, checkboxItem), {}, {
      isChecked: true
    });
    return checkboxItem;
  });
  return result;
}

/**
 * Sets the active filters checkboxes isChecked to true .
 *
 * @param {object} checkboxData
 *  * @param {object} Filters
 * @return {json}
 */

function setSelectedFilterValues(checkboxData, Filters) {
  var result = checkboxData.map(function (filterGroup) {
    if (Array.isArray(Filters[filterGroup.datafield]) && Filters[filterGroup.datafield].length !== 0) {
      return {
        groupName: filterGroup.groupName,
        checkboxItems: setSelectedVlauesToTrue(filterGroup.checkboxItems, Filters[filterGroup.datafield]),
        datafield: filterGroup.datafield,
        show: filterGroup.show,
        section: filterGroup.section,
        tooltip: filterGroup.tooltip
      };
    }
    return filterGroup;
  });
  return result;
}