"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toggleSilder = exports.toggleCheckBox = exports.clearSliderSection = exports.clearFacetSection = exports.clearAllFilters = exports.clearAllAndSelectFacet = void 0;
var _ActionTypes = require("./ActionTypes");
var toggleCheckBox = function toggleCheckBox(toggleCheckBoxItem) {
  return {
    type: _ActionTypes.sideBarActionTypes.FACET_VALUE_CHANGED,
    payload: toggleCheckBoxItem
  };
};
exports.toggleCheckBox = toggleCheckBox;
var toggleSilder = function toggleSilder(slider) {
  return {
    type: _ActionTypes.sideBarActionTypes.ON_TOGGLE_SLIDER,
    payload: slider
  };
};
exports.toggleSilder = toggleSilder;
var clearAllFilters = function clearAllFilters() {
  return {
    type: _ActionTypes.sideBarActionTypes.CLEAR_ALL_FILTERS
  };
};
exports.clearAllFilters = clearAllFilters;
var clearFacetSection = function clearFacetSection(facetSection) {
  return {
    type: _ActionTypes.sideBarActionTypes.CLEAR_FACET_SECTION,
    payload: {
      facetSection: facetSection
    }
  };
};
exports.clearFacetSection = clearFacetSection;
var clearSliderSection = function clearSliderSection(facetSection) {
  return {
    type: _ActionTypes.sideBarActionTypes.CLEAR_SLIDER_SECTION,
    payload: {
      facetSection: facetSection
    }
  };
};
exports.clearSliderSection = clearSliderSection;
var clearAllAndSelectFacet = function clearAllAndSelectFacet(facetValue) {
  return {
    type: _ActionTypes.sideBarActionTypes.CLEAR_AND_SELECT_FACET_VALUE,
    payload: facetValue
  };
};
exports.clearAllAndSelectFacet = clearAllAndSelectFacet;