"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
exports.loadStateFromLocal = void 0;
var _ActionTypes = require("../actions/ActionTypes");
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");
  return typeof key === "symbol" ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (typeof input !== "object" || input === null) return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (typeof res !== "object") return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (hint === "string" ? String : Number)(input);
}
var updateAfterSignInData = function updateAfterSignInData(data, state) {
  var updatedState = _objectSpread({}, state);
  updatedState.isSignedIn = !!(data && data instanceof Object && data.email);
  return _objectSpread(_objectSpread({}, updatedState), data);
};
var updateAfterSignOutData = function updateAfterSignOutData() {
  var updatedState = {};
  updatedState.isSignedIn = false;
  return updatedState;
};

/**
 *  A Function to read Initial login state from Local Storage.
 *
 * @type {object}
 */
var loadStateFromLocal = function loadStateFromLocal(getFromLocalStorage) {
  try {
    var userDetails = getFromLocalStorage('userDetails');
    var DEFAULT_INITIAL_STATE = {
      isSignedIn: false
    };

    /*
      Note for Developer:
      Here we are checking does userDetails object contains at least email or not.
      Email is used in navigation bar as backup property when name is not present.
    */

    if (userDetails.email) {
      return _objectSpread(_objectSpread(_objectSpread({}, DEFAULT_INITIAL_STATE), userDetails), {}, {
        isSignedIn: true
      });
    }
    return DEFAULT_INITIAL_STATE;
  } catch (error) {
    return {
      isSignedIn: false
    };
  }
};

/**
 * Generate reducer for Login
 *
 * @returns {object} reducer object
 */
exports.loadStateFromLocal = loadStateFromLocal;
function _default(getFromLocalStorage) {
  var initLoginState = loadStateFromLocal(getFromLocalStorage);
  return {
    login: function login() {
      var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initLoginState;
      var action = arguments.length > 1 ? arguments[1] : undefined;
      var payload = action.payload,
        type = action.type;
      var updatedState;
      switch (type) {
        case _ActionTypes.ActionTypes.SIGN_IN:
          updatedState = updateAfterSignInData(payload, state);
          return _objectSpread(_objectSpread({}, state), updatedState);
        case _ActionTypes.ActionTypes.SIGN_OUT:
          updatedState = updateAfterSignOutData();
          return _objectSpread({}, updatedState);
        default:
          return state;
      }
    }
  };
}