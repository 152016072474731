"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.DEFAULT_CONFIG_AUTHPROVIDER = void 0;
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _redirect = _interopRequireDefault(require("../utils/redirect"));
var _templateObject;
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }
  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}
var GET_USER_DETAILS = (0, _graphqlTag.default)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\nquery getMyUser {\n  getMyUser {\n    firstName\n    lastName\n    organization\n    userID\n    userStatus\n    email\n    IDP\n    role\n    creationDate\n    editDate\n    acl {\n      armID\n      armName\n      accessStatus\n      requestDate\n      reviewAdminName\n      reviewDate\n      comment\n    }\n  }\n}\n"])));

/**
 * Default configuration for Auth Provider
 */
var DEFAULT_CONFIG_AUTHPROVIDER = {
  // Misc. Configuration Options
  config: {
    GOOGLE_CLIENT_ID: 'Sample Id',
    NIH_CLIENT_ID: 'Sample Id',
    NIH_AUTH_URL: 'https://stsstg.nih.gov/auth/oauth/v2/authorize',
    AUTH_API: '',
    GET_USER_DETAILS: GET_USER_DETAILS
  },
  // Helper functions used by the component
  functions: {
    /**
     * A function that is called when the redirect is needed to be done.
     *
     * @param {object} history object of history.
     * @param {object} redirectPath reason for the change event
     */
    redirect: _redirect.default,
    /**
     * A function that is called when value is needed to store in Local Storage.
     *
     * @param {String} key key of object in Local Storage.
     * @param {Object} value Object or String that needes to be store in Local Storage.
     */
    storeInLocalStorage: function storeInLocalStorage(key, value) {},
    /**
     * A function that is called when value is needed to remove from Local Storage.
     *
     * @param {String} key key of object in Local Storage.
     * @param {Object} value Object or String that needes to be store in Local Storage.
     */
    deleteFromLocalStorage: function deleteFromLocalStorage(key) {}
  }
};
exports.DEFAULT_CONFIG_AUTHPROVIDER = DEFAULT_CONFIG_AUTHPROVIDER;
var _default = DEFAULT_CONFIG_AUTHPROVIDER;
exports.default = _default;