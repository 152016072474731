"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
function getRedirectedType(query) {
  var path = query.get('type') || '/';
  return path;
}
var _default = getRedirectedType;
exports.default = _default;