"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AuthProviderGenerator", {
  enumerable: true,
  get: function get() {
    return _AuthProviderGenerator.AuthProviderGenerator;
  }
});
Object.defineProperty(exports, "AuthenticationMiddlewareGenerator", {
  enumerable: true,
  get: function get() {
    return _AuthenticationMiddlewareGenerator.AuthenticationMiddlewareGenerator;
  }
});
Object.defineProperty(exports, "DEFAULT_CONFIG_AUTHPROVIDER", {
  enumerable: true,
  get: function get() {
    return _config.DEFAULT_CONFIG_AUTHPROVIDER;
  }
});
Object.defineProperty(exports, "DEFAULT_CONFIG_AUTH_MIDDLEWARE", {
  enumerable: true,
  get: function get() {
    return _config2.DEFAULT_CONFIG_AUTH_MIDDLEWARE;
  }
});
Object.defineProperty(exports, "DEFAULT_CONFIG_LOGIN", {
  enumerable: true,
  get: function get() {
    return _config3.DEFAULT_CONFIG_LOGIN;
  }
});
Object.defineProperty(exports, "LoginGenerator", {
  enumerable: true,
  get: function get() {
    return _LoginGenerator.LoginGenerator;
  }
});
Object.defineProperty(exports, "LoginReducerGenerator", {
  enumerable: true,
  get: function get() {
    return _LoginReducerGenerator.default;
  }
});
Object.defineProperty(exports, "accessLevelTypes", {
  enumerable: true,
  get: function get() {
    return _enums.accessLevelTypes;
  }
});
Object.defineProperty(exports, "getIdps", {
  enumerable: true,
  get: function get() {
    return _getIdps.default;
  }
});
Object.defineProperty(exports, "getRedirectPath", {
  enumerable: true,
  get: function get() {
    return _getRedirectPath.default;
  }
});
Object.defineProperty(exports, "loadStateFromLocal", {
  enumerable: true,
  get: function get() {
    return _LoginReducerGenerator.loadStateFromLocal;
  }
});
Object.defineProperty(exports, "membershipStatus", {
  enumerable: true,
  get: function get() {
    return _enums.membershipStatus;
  }
});
Object.defineProperty(exports, "redirect", {
  enumerable: true,
  get: function get() {
    return _redirect.default;
  }
});
Object.defineProperty(exports, "signInRed", {
  enumerable: true,
  get: function get() {
    return _Actions.signInRed;
  }
});
Object.defineProperty(exports, "signOutRed", {
  enumerable: true,
  get: function get() {
    return _Actions.signOutRed;
  }
});
Object.defineProperty(exports, "useAuth", {
  enumerable: true,
  get: function get() {
    return _AuthProviderGenerator.useAuth;
  }
});
Object.defineProperty(exports, "useQuery", {
  enumerable: true,
  get: function get() {
    return _useQuery.default;
  }
});
Object.defineProperty(exports, "userRoles", {
  enumerable: true,
  get: function get() {
    return _enums.userRoles;
  }
});
var _AuthProviderGenerator = require("./AuthenticationProvider/AuthProviderGenerator");
var _config = require("./AuthenticationProvider/config");
var _LoginReducerGenerator = _interopRequireWildcard(require("./store/reducers/LoginReducerGenerator"));
var _Actions = require("./store/actions/Actions");
var _AuthenticationMiddlewareGenerator = require("./AuthenticationMiddleware/AuthenticationMiddlewareGenerator");
var _config2 = require("./AuthenticationMiddleware/config");
var _enums = require("./utils/enums");
var _redirect = _interopRequireDefault(require("./utils/redirect"));
var _LoginGenerator = require("./Login/LoginGenerator");
var _config3 = require("./Login/config");
var _useQuery = _interopRequireDefault(require("./Login/hooks/useQuery"));
var _getIdps = _interopRequireDefault(require("./Login/utils/getIdps"));
var _getRedirectPath = _interopRequireDefault(require("./Login/utils/getRedirectPath"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}
function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }
  if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
    return {
      default: obj
    };
  }
  var cache = _getRequireWildcardCache(nodeInterop);
  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }
  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;
  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;
      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }
  newObj.default = obj;
  if (cache) {
    cache.set(obj, newObj);
  }
  return newObj;
}