"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.custodianUtils = void 0;
/* eslint-disable import/prefer-default-export */
var custodianUtils = {
  getNodeLevelLabel: function getNodeLevelLabel(nodeLabel, nodeLevelAccess) {
    var limit = 30;
    return nodeLevelAccess ? nodeLabel.substring(0, limit) : nodeLabel;
  },
  /**
   * Gets the display format for the authenticator.
   * @param {string} idpValue - The IDP used to login.
   * */
  getAuthenticatorName: function getAuthenticatorName(idpValue) {
    var loginMap = {
      google: 'Google',
      nih: 'NIH',
      'login.gov': 'Login.gov'
    };
    if (Object.prototype.hasOwnProperty.call(loginMap, idpValue)) {
      return loginMap[idpValue];
    }
    return idpValue;
  },
  // Capitalize first letter of each word
  capitalizeFirstLetter: function capitalizeFirstLetter(str) {
    if (str.toLowerCase() === 'non-member') {
      return 'Non-Member';
    }
    if (str.toLowerCase() === 'nih') {
      return 'NIH';
    }
    if (str.toLowerCase() === 'esi') {
      return 'ESI';
    }
    if (str.toLowerCase() === 'google') {
      return 'Google';
    }
    var words = str.split(' ');
    return words.map(function (word) {
      return word.charAt(0).toUpperCase() + word.substring(1);
    }).join(' ');
  }
};
exports.custodianUtils = custodianUtils;