"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.signOutRed = exports.signInRed = void 0;
var _ActionTypes = require("./ActionTypes");
var signInRed = function signInRed(data) {
  return {
    type: _ActionTypes.ActionTypes.SIGN_IN,
    payload: data
  };
};
exports.signInRed = signInRed;
var signOutRed = function signOutRed() {
  return {
    type: _ActionTypes.ActionTypes.SIGN_OUT
  };
};
exports.signOutRed = signOutRed;