"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * Default Modal Styles
 */
var _default = function _default(theme) {
  return {
    modal: {
      display: 'flex',
      padding: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      minWidth: '32%',
      borderRadius: '10px',
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      backgroundColor: theme.palette.background.paper
    },
    closeIcon: {
      cursor: 'pointer'
    },
    closeRoot: {
      height: 10,
      marginBottom: 2
    },
    modalTitle: {
      fontFamily: 'lato',
      borderBottom: '1px solid rgba(#000,0.3)',
      fontSize: 20,
      color: '#4D6787',
      padding: '16px 32px 12px 32px',
      margin: '0px',
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontWeight: 'bold'
    },
    modalContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingRight: 33,
      paddingLeft: 33,
      backgroundColor: '#CCD4DD'
    },
    inputLabel: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    uploadFile: {
      width: 315,
      border: '1px solid white',
      margin: '20px 4px',
      padding: 10,
      textAlign: 'center'
    },
    orTitle: {
      position: 'fixed',
      color: '#437BBE',
      fontSize: 17,
      border: '1px solid #fff',
      borderRadius: '50%',
      padding: '7px 8px 9px 8px',
      width: 42,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: -36,
      backgroundColor: '#CCD4DD',
      fontFamily: 'Lato',
      fontWeight: 'bold',
      marginTop: 5
    },
    textSection: {
      width: 315,
      border: '1px solid white',
      margin: '20px 4px',
      padding: '10px 25px 13px 29px'
    },
    modalFooter: {
      borderTop: '1px solid rgba(#000,0.3)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 20,
      marginBottom: 20
    },
    button: {
      marginLeft: '10px',
      marginRight: '10px',
      borderRadius: '10px',
      boxSizing: 'border-box',
      height: 38,
      width: 97,
      border: '1px solid #626262',
      fontSize: 11,
      textAlign: 'center',
      color: '#fff',
      fontFamily: 'lato',
      boxShadow: 'none'
    },
    modalRoot: {
      background: 'green'
    },
    textArea: {
      overflowY: 'auto !important',
      height: '151px !important',
      width: '100%',
      border: '1.5px solid #437BBE',
      borderRadius: 10,
      fontSize: 15,
      fontFamily: 'Lato',
      fontStyle: 'italic',
      color: '#437BBE',
      padding: '9px 13px',
      '&::placeholder': {
        fontSize: 15,
        fontFamily: 'Lato',
        fontStyle: 'italic',
        color: '#437BBE'
      }
    },
    listTitle: {
      fontWeight: 300,
      fontFamily: 'Nunito',
      color: 'black',
      fontSize: 16
    },
    customTooltip: {
      padding: 5
    },
    customArrow: {
      '&::before': {
        border: '#03A383 1px solid'
      }
    },
    helpIcon: {
      zIndex: '600'
    },
    helpIconButton: {
      marginBottom: 10
    }
  };
};
exports.default = _default;