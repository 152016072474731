"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sideBarActionTypes = exports.Actions = void 0;
var sideBarActionTypes = {
  FACET_VALUE_CHANGED: 'FACET_VALUE_CHANGED',
  ON_TOGGLE_SLIDER: 'ON_TOGGLE_SLIDER',
  CLEAR_ALL_FILTERS: 'CLEAR_ALL_FILTERS',
  CLEAR_FACET_SECTION: 'CLEAR_FACET_SECTION',
  CLEAR_SLIDER_SECTION: 'CLEAR_SLIDER_SECTION',
  CLEAR_AND_SELECT_FACET_VALUE: 'CLEAR_AND_SELECT_FACET_VALUE'
};
exports.sideBarActionTypes = sideBarActionTypes;
var Actions = '';
exports.Actions = Actions;