"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _reactRouterDom = require("react-router-dom");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function useQuery() {
  var _ref = (0, _reactRouterDom.useLocation)(),
    search = _ref.search;
  return _react.default.useMemo(function () {
    return new URLSearchParams(search);
  }, [search]);
}
var _default = useQuery;
exports.default = _default;