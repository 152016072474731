"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.chunkSplit = void 0;
/**
 * Generates an array of arrays, each of the specified length
 *
 * @param {array} arr the array to split
 * @param {number} length the length of each chunk
 * @returns {array[]} the array of chunks
 */
var chunkSplit = function chunkSplit(arr, length) {
  // Check if the array is invalid or empty
  if (!arr || !(arr instanceof Array) || !arr.length) {
    return [];
  }

  // Check if the length is invalid
  var chunkLength = length;
  if (!length || typeof length !== 'number' || length < 1) {
    chunkLength = 50;
  }

  // Split the array into chunks
  var result = [];
  for (var i = 0; i < arr.length; i += chunkLength) {
    result.push(arr.slice(i, i + chunkLength));
  }
  return result;
};
exports.chunkSplit = chunkSplit;
var _default = chunkSplit;
exports.default = _default;