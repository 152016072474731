"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var defaultTheme = function defaultTheme() {
  return {
    MuiTypography: {
      root: {
        color: '#4a4a4a',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: '500',
        lineHeight: '1.5',
        letterSpacing: '0.00938em'
      }
    }
  };
};
var _default = defaultTheme;
exports.default = _default;