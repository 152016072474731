"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var defaultTheme = function defaultTheme() {
  return {
    MuiContainer: {
      maxWidthLg: {
        '@media (min-width: 1280px)': {
          maxWidth: '70vw'
        }
      },
      root: {
        backgroundColor: '#ffffff',
        '@media (min-width: 600px)': {
          padding: '0'
        }
      }
    },
    MuiTableHead: {
      root: {
        height: '50px'
      }
    }
  };
};
var _default = defaultTheme;
exports.default = _default;