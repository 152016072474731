"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var getNodeLevelLabel = function getNodeLevelLabel(nodeLabel, nodeLevelAccess) {
  var limit = 30;
  return nodeLevelAccess ? nodeLabel.substring(0, limit) : nodeLabel;
};

/**
 * Gets the display format for the authenticator.
 * @param {string} idpValue - The IDP used to login.
 * */
var getAuthenticatorName = function getAuthenticatorName(idpValue) {
  var loginMap = {
    google: 'Google',
    nih: 'NIH',
    'login.gov': 'Login.gov'
  };
  if (Object.prototype.hasOwnProperty.call(loginMap, idpValue)) {
    return loginMap[idpValue];
  }
  return idpValue;
};

// Capitalize first letter of each word
var capitalizeFirstLetter = function capitalizeFirstLetter(str) {
  if (str.toLowerCase() === 'non-member') {
    return 'Non-Member';
  }
  if (str.toLowerCase() === 'nih') {
    return 'NIH';
  }
  if (str.toLowerCase() === 'esi') {
    return 'ESI';
  }
  if (str.toLowerCase() === 'google') {
    return 'Google';
  }
  var words = str.split(' ');
  return words.map(function (word) {
    return word.charAt(0).toUpperCase() + word.substring(1);
  }).join(' ');
};

/**
 * transform data with required formation
 * @param {obj} data
 * @param {metaData}
 * @returns obj
 */
var transformData = function transformData(data, metaData) {
  // find field need to be transform.
  var capitalizedField = metaData.filter(function (d) {
    return d.isCapital && d.isCapital === true;
  });
  var noEmptyField = metaData.filter(function (d) {
    return d.noEmpty && d.noEmpty === true;
  });
  var customizedDataArray = [];
  for (var i = 0; i < data.length; i += 1) {
    var expectedData = true;
    var customizedData = data[i];
    if (capitalizedField && capitalizedField.length > 0) {
      // capitalizedField
      for (var j = capitalizedField.length - 1; j >= 0; j -= 1) {
        var field = capitalizedField[j].dataField;
        customizedData[field] = capitalizeFirstLetter(customizedData[field]);
      }
    }
    if (noEmptyField && noEmptyField.length > 0) {
      // checkNoEmptyField
      for (var _j = noEmptyField.length - 1; _j >= 0; _j -= 1) {
        var _field = noEmptyField[_j].dataField;
        if (customizedData[_field] === 0) {
          expectedData = false;
          break;
        }
      }
    }
    if (expectedData) {
      customizedDataArray.push(customizedData);
    }
  }
  return customizedDataArray;
};
var _default = {
  capitalizeFirstLetter: capitalizeFirstLetter,
  getAuthenticatorName: getAuthenticatorName,
  getNodeLevelLabel: getNodeLevelLabel,
  transformData: transformData
};
exports.default = _default;