"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userRoles = exports.membershipStatus = exports.accessLevelTypes = void 0;
var accessLevelTypes = {
  METADATA_ONLY: 'Metadata Only',
  NONE: 'None'
};
exports.accessLevelTypes = accessLevelTypes;
var userRoles = {
  ADMIN: 'admin',
  MEMBER: 'member',
  NONMEMBER: 'non-member'
};
exports.userRoles = userRoles;
var membershipStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
};
exports.membershipStatus = membershipStatus;