"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDateInFormat = getDateInFormat;
/* eslint-disable import/prefer-default-export */
/**
 * Provides timestamp in simple format.
 * @param {string} dateString - date string to be reformatted.
 * @param {string} [strSeparator] - separator for date fields, replaces ( - ).
 * @returns string */
function getDateInFormat(dateString, strSeparator) {
  var date = new Date(dateString);
  var yyyy = date.getFullYear();
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  if (!dateString || !dateString.length) {
    return '';
  }
  if (strSeparator && strSeparator.length) {
    return "".concat(mm).concat(strSeparator).concat(dd).concat(strSeparator).concat(yyyy);
  }
  return "".concat(yyyy, "/").concat(mm, "/").concat(dd);
}