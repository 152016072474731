"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildCSV = buildCSV;
exports.buildMap = buildMap;
exports.createCSVDownload = createCSVDownload;
exports.createFileName = createFileName;
exports.downloadCSV = downloadCSV;
exports.escapeDangerousCSVCharacters = escapeDangerousCSVCharacters;
exports.getCollatorComparator = getCollatorComparator;
exports.getPageValue = getPageValue;
exports.sortCompare = sortCompare;
exports.warnDeprecated = warnDeprecated;
exports.warnInfo = warnInfo;
var _lodash = require("lodash");
/* eslint-disable */

function buildMap(rows) {
  return rows.reduce(function (accum, _ref) {
    var dataIndex = _ref.dataIndex;
    accum[dataIndex] = true;
    return accum;
  }, {});
}
function escapeDangerousCSVCharacters(data) {
  if (typeof data === 'string') {
    // Places single quote before the appearance of dangerous characters if they
    // are the first in the data string.
    return data.replace(/^\+|^\-|^\=|^\@/g, "'$&");
  }
  return data;
}
function warnDeprecated(warning) {
  var consoleWarnings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  if (consoleWarnings) {
    console.warn("Deprecation Notice:  ".concat(warning));
  }
}
function warnInfo(warning) {
  var consoleWarnings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  if (consoleWarnings) {
    console.warn("".concat(warning));
  }
}
function getPageValue(count, rowsPerPage, page) {
  var totalPages = count <= rowsPerPage ? 1 : Math.ceil(count / rowsPerPage);

  // `page` is 0-indexed
  return page >= totalPages ? totalPages - 1 : page;
}
function getCollatorComparator() {
  if (Intl) {
    var collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base'
    });
    return collator.compare;
  }
  var fallbackComparator = function fallbackComparator(a, b) {
    return a.localeCompare(b);
  };
  return fallbackComparator;
}
function sortCompare(order) {
  return function (a, b) {
    var aData = a.data === null || typeof a.data === 'undefined' ? '' : a.data;
    var bData = b.data === null || typeof b.data === 'undefined' ? '' : b.data;
    return (typeof aData.localeCompare === 'function' ? aData.localeCompare(bData) : aData - bData) * (order === 'asc' ? 1 : -1);
  };
}
function buildCSV(columns, data, options) {
  var replaceDoubleQuoteInString = function replaceDoubleQuoteInString(columnData) {
    return typeof columnData === 'string' ? columnData.replace(/\"/g, '""') : columnData;
  };
  var replacePoundSignSeparator = function replacePoundSignSeparator(columnData) {
    return columnData && columnData.toString().includes('#') ? columnData.replaceAll('#', ',') : columnData;
  };
  var buildHead = function buildHead(columns) {
    return "".concat(columns.reduce(function (soFar, column) {
      var arg = column.icon ? column.iconLabel : column.label || column.name;
      return column.download ? "".concat(soFar, "\"").concat(escapeDangerousCSVCharacters(replaceDoubleQuoteInString(arg)), "\"").concat(options.downloadOptions.separator) : soFar;
    }, '').slice(0, -1), "\r\n");
  };
  var CSVHead = buildHead(columns);
  var buildBody = function buildBody(data) {
    if (!data.length) return '';
    return data.reduce(function (soFar, row) {
      return "".concat(soFar, "\"").concat(row.data.filter(function (_, index) {
        return columns[index].download;
      }).map(function (el, index) {
        if (columns[index].icon) {
          if (el === 0) {
            el = columns[index].csvNullValue;
          }
          if ((0, _lodash.isArray)(el) && el.length === 0) {
            el = columns[index].csvNullValue;
          }
          if ((0, _lodash.isArray)(el)) {
            el = el.map(function (el) {
              return el.url;
            }).join(', ');
          }
        }
        return el;
      }).map(function (columnData) {
        return escapeDangerousCSVCharacters(replaceDoubleQuoteInString(replacePoundSignSeparator(columnData)));
      }).join("\"".concat(options.downloadOptions.separator, "\"")), "\"\r\n");
    }, '').trim();
  };
  var CSVBody = buildBody(data);
  var csv = options.onDownload ? options.onDownload(buildHead, buildBody, columns, data) : "".concat(CSVHead).concat(CSVBody).trim();
  return csv;
}
function downloadCSV(csv, filename) {
  var blob = new Blob([csv], {
    type: 'text/csv'
  });

  /* taken from react-csv */
  if (navigator && navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    var dataURI = "data:text/csv;charset=utf-8,".concat(csv);
    var URL = window.URL || window.webkitURL;
    var downloadURI = typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.setAttribute('href', downloadURI);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
function createFileName(fileName) {
  var date = new Date();
  var yyyy = date.getFullYear();
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  if (dd < 10) {
    dd = "0".concat(dd);
  }
  if (mm < 10) {
    mm = "0".concat(mm);
  }
  var todaysDate = "".concat(yyyy, "-").concat(mm, "-").concat(dd);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();
  if (hours < 10) {
    hours = "0".concat(hours);
  }
  if (minutes < 10) {
    minutes = "0".concat(minutes);
  }
  if (seconds < 10) {
    seconds = "0".concat(seconds);
  }
  return "".concat(fileName, " ").concat(todaysDate, " ").concat(hours, "-").concat(minutes, "-").concat(seconds, '.csv');
}
function createCSVDownload(columns, data, options, downloadCSV) {
  var csv = buildCSV(columns, data, options);
  if (options.onDownload && csv === false) {
    return;
  }
  downloadCSV(csv, createFileName(options.downloadOptions.filename));
}