"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "DataAccessRequest", {
  enumerable: true,
  get: function get() {
    return _DataAccessRequest.default;
  }
});
var _DataAccessRequest = _interopRequireDefault(require("./DataAccessRequest"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}