"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.countValues = countValues;
exports.default = void 0;
/**
 * Calculates the sum of all values in an object
 *
 * Useful for calculating the total number of search results (e.g. for all tabs)
 *
 * @param {object} obj - the object to sum
 * @returns {number} the sum of all values in the object or 0 if the object is empty
 */
function countValues(obj) {
  if (!obj || typeof obj !== 'object') {
    return 0;
  }
  var count = Object.values(obj).reduce(function (acc, val) {
    return acc + (typeof val === 'number' ? val : 0);
  }, 0);
  return typeof count === 'number' ? count : 0;
}
var _default = countValues;
exports.default = _default;