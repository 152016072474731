"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _core = require("@material-ui/core");
var _default = (0, _core.withStyles)(function () {
  return {
    root: {
      padding: '51px 75px 10px',
      height: '65px',
      color: '#000000',
      fontFamily: 'Nunito',
      fontSize: '17px',
      fontWeight: '300',
      letterSpacing: '0',
      lineHeight: '24px'
    },
    buttonGroup: {
      textAlign: 'center'
    }
  };
})(_core.DialogContent);
exports.default = _default;