"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ProfileView", {
  enumerable: true,
  get: function get() {
    return _ProfileView.default;
  }
});
var _ProfileView = _interopRequireDefault(require("./ProfileView"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}