"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = function _default() {
  return {
    dropDownIconSection: {
      fill: '#000000'
    },
    ExpansionPaneldropDownIcon: {
      left: '-98%'
    },
    expansionPanelDetailsRoot: {
      display: 'block'
    }
  };
};
exports.default = _default;