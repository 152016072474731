"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = function _default() {
  return {
    expansionPanelRoot: {
      boxShadow: 'none',
      background: '#EAEAEA',
      margin: 'auto',
      position: 'initial',
      '&:before': {
        position: 'initial'
      }
    },
    dropDownIconSection: {
      fill: '#000000'
    },
    ExpansionPaneldropDownIcon: {
      left: '-98%'
    },
    sectionSummaryText: {
      color: '#323232',
      fontFamily: 'Raleway',
      fontSize: '15px',
      fontWeight: 'bold',
      letterSpacing: '0.25px',
      marginLeft: '15px',
      lineHeight: '26px'
    },
    expansionPanelDetailsRoot: {
      display: 'block'
    },
    expansionPanelsideBarItem: {
      margin: '0',
      position: 'initial',
      boxShadow: 'none',
      backgroundColor: '#f4f4f4'
    }
  };
};
exports.default = _default;