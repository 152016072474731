"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var loginStyle = {
  Container: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Nunito'
  },
  NoBold: {
    fontWeight: 'normal'
  },
  pageTitle: {
    color: '#3974A8',
    fontSize: '30px',
    textAlign: 'center',
    fontFamily: 'Nunito',
    fontWeight: '500',
    lineHeight: '40px',
    marginBottom: '10px',
    marginTop: '10px'
  },
  Box: {
    boxShadow: '-4px 8px 27px 4px rgba(27,28,28,0.09);',
    border: '#A9C8E3 2px solid',
    borderRadius: '10px',
    margin: '10px 0px',
    padding: '5px !important',
    backgroundColor: '#F2F6FA'
  },
  LoginBoxTitle: {
    height: '16px',
    width: '350px',
    color: '#092E50',
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '22px',
    textAlign: 'center',
    marginTop: '35px',
    marginBottom: '25px',
    fontWeight: 600
  },
  LoginButtonGroup: {},
  LoginButton: {
    color: '#092E50',
    width: '257px',
    height: '35px',
    background: '#FFFFFF',
    boxShadow: 'none',
    border: '1px #B1B1B1 solid',
    marginBottom: '14px',
    justifyContent: 'left',
    textTransform: 'none'
  },
  helperMessage: {
    textAlign: 'center',
    width: '397px',
    color: '#323232',
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontWeight: '300',
    letterSpacing: '0',
    lineHeight: '22px'
  },
  createAccountMessage: {
    marginTop: '4px',
    marginBottom: '18px'
  },
  RegisterBox: {
    justifyContent: 'center',
    paddingLeft: '50px !important',
    paddingRight: '50px !important'
  },
  RegisterBoxTitle: {
    height: '16px',
    width: '271px',
    color: '#092E50',
    fontFamily: 'Nunito',
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '22px',
    textAlign: 'center',
    marginTop: '35px',
    marginBottom: '25px'
  },
  registerButtton: {
    height: '40px',
    color: '#FFFFFF',
    backgroundColor: '#5D53F6',
    '&:hover': {
      backgroundColor: '#5D53F6'
    }
  },
  registerHelpMessage: {
    marginTop: '18px',
    marginBottom: '18px'
  },
  supportEmail: {
    display: 'inline !important',
    fontWeight: 'bold'
  },
  emptySpace: {
    height: '50px'
  },
  extraSpaceInBorrom: {
    height: '50px'
  }
};
var _default = loginStyle;
exports.default = _default;