"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = function _default() {
  return {
    expansionPanelDetailsRoot: {
      display: 'block'
    },
    expansionPanelsideBarItem: {
      boxShadow: 'none',
      borderTop: '1px solid #D2D2D2',
      '&:last-child': {
        borderBottom: '1px solid #D2D2D2'
      },
      margin: 'auto',
      position: 'initial',
      '&:before': {
        position: 'initial'
      }
    },
    subSectionSummaryText: {
      marginLeft: '10px',
      lineHeight: 0,
      color: '#323232',
      fontFamily: 'Raleway',
      fontSize: '13px',
      fontWeight: 'bold',
      letterSpacing: '0.25px'
    },
    sortGroup: {
      paddingTop: '10px',
      marginBottom: '5px',
      borderTop: '1px solid #B1B1B1',
      textAlign: 'left',
      marginLeft: '-5px'
    },
    sortGroupIcon: {
      cursor: 'pointer',
      fontFamily: 'Nunito',
      fontSize: '10px',
      marginRight: '12px',
      marginLeft: '16px'
    },
    sortGroupItem: {
      cursor: 'pointer',
      fontFamily: 'Nunito',
      fontSize: '10px',
      marginRight: '32px'
    },
    NonSortGroup: {
      marginBottom: '5px',
      borderTop: '1px solid #B1B1B1',
      textAlign: 'left',
      paddingLeft: '10px'
    },
    NonSortGroupItem: {
      fontFamily: 'Nunito',
      fontSize: '10px',
      marginRight: '32px'
    },
    sortGroupItemCounts: {
      cursor: 'pointer',
      fontFamily: 'Nunito',
      fontSize: '10px',
      float: 'right',
      marginRight: '10px',
      marginTop: '5px'
    },
    highlight: {
      color: '#b2c6d6'
    },
    showMore: {
      textAlign: 'right',
      paddingRight: '5px',
      cursor: 'pointer',
      fontSize: '10px',
      width: '100%'
    }
  };
};
exports.default = _default;