"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _loginStyle = _interopRequireDefault(require("../loginStyle"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");
  return typeof key === "symbol" ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (typeof input !== "object" || input === null) return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (typeof res !== "object") return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (hint === "string" ? String : Number)(input);
}
var generateStyle = function generateStyle() {
  var customStyle = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var style = _objectSpread({}, _loginStyle.default);
  if (customStyle.Container) {
    style.Container = _objectSpread(_objectSpread({}, style.Container), customStyle.Container);
  }
  if (customStyle.NoBold) {
    style.NoBold = _objectSpread(_objectSpread({}, style.NoBold), customStyle.NoBold);
  }
  if (customStyle.pageTitle) {
    style.pageTitle = _objectSpread(_objectSpread({}, style.pageTitle), customStyle.pageTitle);
  }
  if (customStyle.Box) {
    style.Box = _objectSpread(_objectSpread({}, style.Box), customStyle.Box);
  }
  if (customStyle.LoginButtonGroup) {
    style.LoginButtonGroup = _objectSpread(_objectSpread({}, style.LoginButtonGroup), customStyle.LoginButtonGroup);
  }
  if (customStyle.LoginBoxTitle) {
    style.LoginBoxTitle = _objectSpread(_objectSpread({}, style.LoginBoxTitle), customStyle.LoginBoxTitle);
  }
  if (customStyle.LoginButton) {
    style.LoginButton = _objectSpread(_objectSpread({}, style.LoginButton), customStyle.LoginButton);
  }
  if (customStyle.createAccountMessage) {
    style.createAccountMessage = _objectSpread(_objectSpread({}, style.createAccountMessage), customStyle.createAccountMessage);
  }
  if (customStyle.RegisterBox) {
    style.RegisterBox = _objectSpread(_objectSpread({}, style.RegisterBox), customStyle.RegisterBox);
  }
  if (customStyle.RegisterBoxTitle) {
    style.RegisterBoxTitle = _objectSpread(_objectSpread({}, style.RegisterBoxTitle), customStyle.RegisterBoxTitle);
  }
  if (customStyle.registerButtton) {
    style.registerButtton = _objectSpread(_objectSpread({}, style.registerButtton), customStyle.registerButtton);
  }
  if (customStyle.registerHelpMessage) {
    style.registerHelpMessage = _objectSpread(_objectSpread({}, style.registerHelpMessage), customStyle.registerHelpMessage);
  }
  if (customStyle.supportEmail) {
    style.supportEmail = _objectSpread(_objectSpread({}, style.supportEmail), customStyle.supportEmail);
  }
  if (customStyle.emptySpace) {
    style.emptySpace = _objectSpread(_objectSpread({}, style.emptySpace), customStyle.emptySpace);
  }
  if (customStyle.Color_092E50) {
    style.Color_092E50 = _objectSpread(_objectSpread({}, style.Color_092E50), customStyle.Color_092E50);
  }
  return style;
};
var _default = generateStyle;
exports.default = _default;