"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * Given a number of classes, this will filter out any undefined values
 * and return a string that can be passed into the className property
 * of a react component. Used as a helper to concat classes in jss.
 * @param {...string} args
 * @return {string[]}
 */
var cn = function cn() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }
  return args.filter(function (klass) {
    return klass;
  }).join(' ');
};
var _default = cn;
exports.default = _default;