"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.ActionTypes = void 0;
var ActionTypes = {
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT'
};
exports.ActionTypes = ActionTypes;
var _default = ActionTypes;
exports.default = _default;