"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = function _default() {
  return {
    listItemGutters: {
      padding: '10px 20px 10px 0px'
    },
    checkboxRoot: {
      marginLeft: '5px',
      height: 12
    },
    panelDetailText: {
      color: '#323232',
      fontFamily: 'Nunito',
      fontSize: '14px',
      fontWeight: '200'
    },
    panelSubjectText: {
      color: '#323232',
      fontFamily: 'Nunito',
      fontSize: '14px',
      marginRight: '0px'
    },
    checkboxLabel: {
      margin: '0'
    },
    checkboxName: {
      margin: '0',
      color: '#000000',
      fontSize: '14px',
      marginTop: '1.5px',
      fontFamily: 'Nunito',
      lineHeight: '120%'
    }
  };
};
exports.default = _default;