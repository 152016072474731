"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _ActionTypes = require("../actions/ActionTypes");
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");
  return typeof key === "symbol" ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (typeof input !== "object" || input === null) return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (typeof res !== "object") return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (hint === "string" ? String : Number)(input);
}
/**
 * Initial state for LocalFind
 *
 * @type {object}
 */
var initFilterState = {
  upload: [],
  autocomplete: [],
  uploadMetadata: {}
};
var updateUploadData = function updateUploadData(data, state) {
  var updatedState = _objectSpread({}, state);
  updatedState.upload = data && data instanceof Array && data.length ? data : [];
  return updatedState;
};
var updateUploadMetadata = function updateUploadMetadata(data, state) {
  var updatedState = _objectSpread({}, state);
  updatedState.uploadMetadata = data;
  return updatedState;
};
var updateAutocompleteData = function updateAutocompleteData(data, state) {
  var updatedState = _objectSpread({}, state);
  updatedState.autocomplete = data && data instanceof Array && data.length ? data : [];
  return updatedState;
};

/**
 * Generate reducer for LocalFind
 *
 * @returns {object} reducer object
 */
function _default() {
  return {
    localFind: function localFind() {
      var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initFilterState;
      var action = arguments.length > 1 ? arguments[1] : undefined;
      var payload = action.payload,
        type = action.type;
      var updatedState;
      switch (type) {
        case _ActionTypes.ActionTypes.UPDATE_LOCALFIND_UPLOAD_DATA:
          updatedState = updateUploadData(payload, state);
          return _objectSpread(_objectSpread({}, state), updatedState);
        case _ActionTypes.ActionTypes.UPDATE_LOCALFIND_AUTOCOMPLETE_DATA:
          updatedState = updateAutocompleteData(payload, state);
          return _objectSpread(_objectSpread({}, state), updatedState);
        case _ActionTypes.ActionTypes.UPDATE_LOCALFIND_UPLOAD_METADATA:
          updatedState = updateUploadMetadata(payload, state);
          return _objectSpread(_objectSpread({}, state), updatedState);
        case _ActionTypes.ActionTypes.RESET_LOCALFIND_UPLOAD_DATA:
          updatedState = updateUploadData([], state);
          updatedState = updateUploadMetadata({}, updatedState);
          return _objectSpread(_objectSpread({}, state), updatedState);
        case _ActionTypes.ActionTypes.RESET_LOCALFIND_ALL_DATA:
          updatedState = updateUploadData([], state);
          updatedState = updateAutocompleteData([], updatedState);
          updatedState = updateUploadMetadata({}, updatedState);
          return _objectSpread(_objectSpread({}, state), updatedState);
        default:
          return state;
      }
    }
  };
}