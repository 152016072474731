"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var isDisabledMember = function isDisabledMember(role, status) {
  var roles = ['admin', 'member'];
  if (roles.includes(role.toLowerCase()) && status.toLowerCase === 'disabled') {
    return false;
  }
  return true;
};
var _default = isDisabledMember;
exports.default = _default;