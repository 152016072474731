"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var getDefaultACL = function getDefaultACL(availableArms) {
  return (availableArms[0] || []).id;
};
var _default = getDefaultACL;
exports.default = _default;