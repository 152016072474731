"use strict";

var _slicedToArray = require("/usr/src/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var defaultIdP = {
  google: {
    key: 'google',
    icon: 'https://raw.githubusercontent.com/CBIIT/datacommons-assets/main/bento/images/icons/png/google.png',
    loginButtonText: 'Sign in with Google'
  }
};

/**
 * Returns an object containing the enabled identity providers
 * @param {Array} enabledAuthProviders - List of enabled identity providers
 * @param {Object} authenticationProviders - List of available identity providers with their details
 * @returns {Object} - Object containing enabled identity providers
 */
var getIdps = function getIdps(enabledAuthProviders) {
  var authenticationProviders = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultIdP;
  var idps = Object.fromEntries(Object.entries(authenticationProviders).filter(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
      key = _ref2[0];
    return enabledAuthProviders.includes(key);
  }));
  if (typeof idps === 'undefined' || Object.values(idps).length === 0) {
    idps = defaultIdP;
  }
  return idps;
};
var _default = getIdps;
exports.default = _default;