"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "SESSION_MANAGER_CONFIG", {
  enumerable: true,
  get: function get() {
    return _config.default;
  }
});
Object.defineProperty(exports, "SESSION_MANAGER_STYLES", {
  enumerable: true,
  get: function get() {
    return _styles.default;
  }
});
Object.defineProperty(exports, "SessionTimeoutGenerator", {
  enumerable: true,
  get: function get() {
    return _SessionTimeoutGenerator.SessionTimeoutGenerator;
  }
});
var _SessionTimeoutGenerator = require("./Generators/SessionTimeoutGenerator");
var _config = _interopRequireDefault(require("./Generators/config"));
var _styles = _interopRequireDefault(require("./Generators/styles"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}