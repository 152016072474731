"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertToCSV = convertToCSV;
exports.createFileName = createFileName;
exports.downloadJson = downloadJson;
function createFileName(fileName) {
  var date = new Date();
  var yyyy = date.getFullYear();
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  if (dd < 10) {
    dd = "0".concat(dd);
  }
  if (mm < 10) {
    mm = "0".concat(mm);
  }
  var todaysDate = "".concat(yyyy, "-").concat(mm, "-").concat(dd);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();
  if (hours < 10) {
    hours = "0".concat(hours);
  }
  if (minutes < 10) {
    minutes = "0".concat(minutes);
  }
  if (seconds < 10) {
    seconds = "0".concat(seconds);
  }
  return "".concat(fileName, " ").concat(todaysDate, " ").concat(hours, "-").concat(minutes, "-").concat(seconds, '.csv');
}
function convertToCSV(jsonse, comments, keysToInclude, header) {
  var objArray = jsonse;
  var array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  var str = '';
  array.map(function (entry, index) {
    var line = '';
    keysToInclude.map(function (keyName) {
      if (line !== '') line += ',';
      var columnResult = entry[keyName];
      if (typeof columnResult === 'string') columnResult.replace(/"/g, '""');
      if (typeof columnResult === 'string' && columnResult.search(/("|,|\n)/g) >= 0) columnResult = "\"".concat(columnResult, "\"");
      line += columnResult !== null ? columnResult : ' ';
      return line;
    });
    if (index === 0) {
      str = header.join(',');
      var commentResult = comments.replace(/"/g, '""');
      if (commentResult.search(/("|,|\n)/g) >= 0) commentResult = "\"".concat(commentResult, "\"");
      str += "\r\n".concat(line, ",").concat(commentResult, "\r\n");
    } else {
      str += "".concat(line, "\r\n");
    }
    return str;
  });
  return str;
}
function downloadJson(_ref) {
  var tableData = _ref.tableData,
    comment = _ref.comment,
    manifestFileName = _ref.manifestFileName,
    manifestData = _ref.manifestData;
  var jsonse = JSON.stringify(tableData);
  var csv = convertToCSV(jsonse, comment, manifestData.keysToInclude, manifestData.header);
  var exportData = new Blob(["\uFEFF".concat(csv)], {
    type: 'text/csv;charset=utf-8'
  });
  var JsonURL = window.URL.createObjectURL(exportData);
  var tempLink = '';
  tempLink = document.createElement('a');
  tempLink.setAttribute('href', JsonURL);
  tempLink.setAttribute('download', createFileName(manifestFileName));
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
}