"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.silderTypes = exports.InputTypes = void 0;
var InputTypes = {
  CHECKBOX: 'checkbox',
  SLIDER: 'slider',
  LOCAL_SEARCH: 'local_search'
};
exports.InputTypes = InputTypes;
var silderTypes = {
  INPUT_MIN: 'INPUT_MIN',
  INPUT_MAX: 'INPUT_MAX'
};
exports.silderTypes = silderTypes;