"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/**
 * Review Requests Table styles
 */
var _default = function _default() {
  return {};
};
exports.default = _default;