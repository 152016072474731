"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.DEFAULT_CONFIG_USER_DETAILS = void 0;
/* eslint-disable no-unused-vars */
/**
 * Default configuration for Manage Access Table
 */
var DEFAULT_CONFIG_USER_DETAILS = {
  // Misc. Configuration Options
  config: {
    inputPlaceholder: 'e.g. BENTO-CASE-06, BENTO-CASE-22',
    noOptionsText: 'No matching items found',
    searchType: 'subjectIds'
  },
  // Helper functions used by the component
  functions: {
    /**
     * Details will be added soon
     *
     */
    cn: function cn(key, value) {}
  }
};
exports.DEFAULT_CONFIG_USER_DETAILS = DEFAULT_CONFIG_USER_DETAILS;
var _default = DEFAULT_CONFIG_USER_DETAILS;
exports.default = _default;