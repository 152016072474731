/* eslint-disable no-unused-vars */
import clsx from 'clsx';
/**
 * Default configuration for Local Find Upload Modal
 */
export const USER_DETAILS_CONFIG = {
  // Misc. Configuration Options
  config: {
  },

  // Helper functions used by the component
  functions: {
    /**
     * Additinoal details will be added soon
     *
     *
     */
    cn: clsx,
  },
};

export default USER_DETAILS_CONFIG;
