"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.onDeleteCartFile = exports.onDeleteAllCartFile = exports.onAddCartFiles = exports.initCart = exports.actionTypes = void 0;
var actionTypes = {
  INIT_CART: 'INIT_CART',
  ADD_CART_FILES: 'ADD_CART_FILES',
  DELETE_CART_FILE: 'DELETE_CART_FILE',
  DELETE_ALL_CART_FILES: 'DELETE_ALL_CART_FILES'
};
exports.actionTypes = actionTypes;
var initCart = function initCart() {
  return {
    type: actionTypes.INIT_CART
  };
};
exports.initCart = initCart;
var onAddCartFiles = function onAddCartFiles(files) {
  return {
    type: actionTypes.ADD_CART_FILES,
    payload: files
  };
};
exports.onAddCartFiles = onAddCartFiles;
var onDeleteCartFile = function onDeleteCartFile(fileId) {
  return {
    type: actionTypes.DELETE_CART_FILE,
    payload: fileId
  };
};
exports.onDeleteCartFile = onDeleteCartFile;
var onDeleteAllCartFile = function onDeleteAllCartFile() {
  return {
    type: actionTypes.DELETE_ALL_CART_FILES
  };
};
exports.onDeleteAllCartFile = onDeleteAllCartFile;