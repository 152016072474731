"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dataAccessRequestStyle = _interopRequireDefault(require("../dataAccessRequestStyle"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");
  return typeof key === "symbol" ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (typeof input !== "object" || input === null) return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (typeof res !== "object") return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (hint === "string" ? String : Number)(input);
}
var generateStyle = function generateStyle() {
  var customStyle = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var style = _objectSpread({}, _dataAccessRequestStyle.default);
  if (customStyle.Container) {
    style.Container = _objectSpread(_objectSpread({}, style.Container), customStyle.Container);
  }
  if (customStyle.pageTitle) {
    style.pageTitle = _objectSpread(_objectSpread({}, style.pageTitle), customStyle.pageTitle);
  }
  if (customStyle.pageTitleUnderline) {
    style.pageTitleUnderline = _objectSpread(_objectSpread({}, style.pageTitleUnderline), customStyle.pageTitleUnderline);
  }
  if (customStyle.container) {
    style.container = _objectSpread(_objectSpread({}, style.container), customStyle.container);
  }
  if (customStyle.brace) {
    style.brace = _objectSpread(_objectSpread({}, style.brace), customStyle.brace);
  }
  if (customStyle.SummaryBox) {
    style.SummaryBox = _objectSpread(_objectSpread({}, style.SummaryBox), customStyle.SummaryBox);
  }
  if (customStyle.row) {
    style.row = _objectSpread(_objectSpread({}, style.row), customStyle.row);
  }
  if (customStyle.column) {
    style.column = _objectSpread(_objectSpread({}, style.column), customStyle.column);
  }
  if (customStyle.itemTitles) {
    style.itemTitles = _objectSpread(_objectSpread({}, style.itemTitles), customStyle.itemTitles);
  }
  if (customStyle.itemValue) {
    style.itemValue = _objectSpread(_objectSpread({}, style.itemValue), customStyle.itemValue);
  }
  if (customStyle.Box) {
    style.Box = _objectSpread(_objectSpread({}, style.Box), customStyle.Box);
  }
  if (customStyle.helperMessage) {
    style.helperMessage = _objectSpread(_objectSpread({}, style.helperMessage), customStyle.helperMessage);
  }
  if (customStyle.createAccountMessage) {
    style.createAccountMessage = _objectSpread(_objectSpread({}, style.createAccountMessage), customStyle.createAccountMessage);
  }
  if (customStyle.formButton) {
    style.formButton = _objectSpread(_objectSpread({}, style.formButton), customStyle.formButton);
  }
  if (customStyle.goToHomeButton) {
    style.goToHomeButton = _objectSpread(_objectSpread({}, style.goToHomeButton), customStyle.goToHomeButton);
  }
  if (customStyle.submitButton) {
    style.submitButton = _objectSpread(_objectSpread({}, style.submitButton), customStyle.submitButton);
  }
  if (customStyle.emptySpace) {
    style.emptySpace = _objectSpread(_objectSpread({}, style.emptySpace), customStyle.emptySpace);
  }
  if (customStyle.inputSelect) {
    style.inputSelect = _objectSpread(_objectSpread({}, style.inputSelect), customStyle.inputSelect);
  }
  if (customStyle.inputText) {
    style.inputText = _objectSpread(_objectSpread({}, style.inputText), customStyle.inputText);
  }
  if (customStyle.chips) {
    style.chips = _objectSpread(_objectSpread({}, style.chips), customStyle.chips);
  }
  if (customStyle.chip) {
    style.chip = _objectSpread(_objectSpread({}, style.chip), customStyle.chip);
  }
  if (customStyle.selectMenuItem) {
    style.selectMenuItem = _objectSpread(_objectSpread({}, style.selectMenuItem), customStyle.selectMenuItem);
  }
  if (customStyle.required) {
    style.required = _objectSpread(_objectSpread({}, style.required), customStyle.required);
  }
  if (customStyle.formLabel) {
    style.formLabel = _objectSpread(_objectSpread({}, style.formLabel), customStyle.formLabel);
  }
  if (customStyle.requiredFieldMessage) {
    style.requiredFieldMessage = _objectSpread(_objectSpread({}, style.requiredFieldMessage), customStyle.requiredFieldMessage);
  }
  return style;
};
var _default = generateStyle;
exports.default = _default;