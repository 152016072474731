"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.DEFAULT_CONFIG_LOGIN = void 0;
var _AuthProviderGenerator = require("../AuthenticationProvider/AuthProviderGenerator");
var _redirect = _interopRequireDefault(require("../utils/redirect"));
var _useQuery = _interopRequireDefault(require("./hooks/useQuery"));
var _getRedirectPath = _interopRequireDefault(require("./utils/getRedirectPath"));
var _getIdps = _interopRequireDefault(require("./utils/getIdps"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/**
 * Default configuration for Login
 */
var DEFAULT_CONFIG_LOGIN = {
  // Misc. Configuration Options
  config: {},
  // Helper functions used by the component
  functions: {
    /**
     * A function that is called on page load and returns the available sign-in options,
     * including signInWithGoogle and signInWithNIH.
     *
     * @returns {Array} An array of available sign-in options
     */
    useAuth: _AuthProviderGenerator.useAuth,
    /**
     *
     * Redirects the user to the specified path.
     * @param {any} historyObject
     * @param {string} path - The path to redirect the user to.
     * @returns {any}
     */
    redirect: _redirect.default,
    /**
     * Custom hook that uses `useLocation` from `react-router-dom`
     * to get the current location object and `React.useMemo` to memoize
     * the `URLSearchParams` object from the `search` property
     * of the location object.
     *
     *  The `URLSearchParams` object can be used to read URL query parameters.
     *
     * @returns {URLSearchParams} The `URLSearchParams` object containing the query parameters.
     */
    useQuery: _useQuery.default,
    /**
     * Returns the value of the "redirect" parameter in the query string of the current URL,
     * or "/" if the "redirect" parameter is not present in the query string.
     *
     * @returns {string} The redirect path as a string.
     */
    getRedirectPath: _getRedirectPath.default,
    /**
     * Returns an object containing the enabled identity providers
     * @param {Array} enabledAuthProviders - List of enabled identity providers
     * @param {Object} authenticationProviders - List of available identity providers
     * @returns {Object} - Object containing enabled identity providers
     */
    getIdps: _getIdps.default
  }
};
exports.DEFAULT_CONFIG_LOGIN = DEFAULT_CONFIG_LOGIN;
var _default = DEFAULT_CONFIG_LOGIN;
exports.default = _default;