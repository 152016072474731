"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultTheme = void 0;
/* eslint-disable import/prefer-default-export */
var defaultTheme = function defaultTheme() {
  return {
    defaultStyle: {
      fontFamily: 'Open Sans',
      textTransform: 'none',
      fontSize: '17px'
    },
    flexContainer: {
      flexDirection: 'column'
    },
    indicator: {
      display: 'none'
    },
    tabHighlightColor: {
      color: '#6d9eba'
    },
    tabs: {
      paddingLeft: '10px'
    },
    MuiTab: {
      root: {
        fontFamily: 'Open Sans',
        textTransform: 'none',
        fontSize: '17px'
      }
    }
  };
};
exports.defaultTheme = defaultTheme;