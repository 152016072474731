"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFilters = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/* eslint-disable import/prefer-default-export */
/* eslint-disable quotes */
/* eslint-disable import/prefer-default-export */
/* eslint-disable quotes */

/* eslint-disable react/jsx-indent */
var getFilters = function getFilters(activeFilters) {
  if (!_lodash.default.isEmpty(activeFilters)) {
    var filters = Object.keys(activeFilters).reduce(function (acc, key) {
      if (Array.isArray(activeFilters[key])) {
        acc[key] = activeFilters[key];
      } else {
        var arr = Object.keys(activeFilters[key]);
        acc[key] = arr;
      }
      return acc;
    }, {});
    return filters;
  }
  return {};
};
exports.getFilters = getFilters;