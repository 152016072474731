"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setTotalRowCount = exports.onRowsPerPageChange = exports.onRowSeclect = exports.onPageChange = exports.onPageAndTotalCountChange = exports.onColumnViewChange = exports.onColumnSort = exports.onChangeSortDirection = exports.actionTypes = void 0;
var actionTypes = {
  VIEW_COLUMN_CHANGE: 'VIEW_COLUMN_CHANGE',
  ON_COLUMN_SORT: 'ON_COLUMN_SORT',
  ON_SORT_ORDER_CHANGE: 'ON_SORT_ORDER_CHANGE',
  ON_ROWS_PER_PAGE_CHANGE: 'ON_ROWS_PER_PAGE_CHANGE',
  ON_PAGE_CHANGE: 'ON_PAGE_CHANGE',
  ON_ROW_SELECT: 'ON_ROW_SELECT',
  SET_TOTAL_ROW_COUNT: 'SET_TOTAL_ROW_COUNT',
  ON_PAGE_AND_TOTAL_COUNT_CHANGE: 'ON_PAGE_AND_TOTAL_COUNT_CHANGE'
};
exports.actionTypes = actionTypes;
var onColumnViewChange = function onColumnViewChange(columns) {
  return {
    type: actionTypes.VIEW_COLUMN_CHANGE,
    payload: columns
  };
};
exports.onColumnViewChange = onColumnViewChange;
var onColumnSort = function onColumnSort(value) {
  return {
    type: actionTypes.ON_COLUMN_SORT,
    payload: value
  };
};
exports.onColumnSort = onColumnSort;
var onChangeSortDirection = function onChangeSortDirection(sortOrder) {
  return {
    type: actionTypes.ON_COLUMN_SORT,
    payload: sortOrder
  };
};
exports.onChangeSortDirection = onChangeSortDirection;
var onRowsPerPageChange = function onRowsPerPageChange(value) {
  return {
    type: actionTypes.ON_ROWS_PER_PAGE_CHANGE,
    payload: value
  };
};
exports.onRowsPerPageChange = onRowsPerPageChange;
var onPageAndTotalCountChange = function onPageAndTotalCountChange(value) {
  return {
    type: actionTypes.ON_PAGE_AND_TOTAL_COUNT_CHANGE,
    payload: value
  };
};
exports.onPageAndTotalCountChange = onPageAndTotalCountChange;
var onPageChange = function onPageChange(pageNumb) {
  return {
    type: actionTypes.ON_PAGE_CHANGE,
    payload: pageNumb
  };
};
exports.onPageChange = onPageChange;
var onRowSeclect = function onRowSeclect(rows) {
  return {
    type: actionTypes.ON_ROW_SELECT,
    payload: rows
  };
};
exports.onRowSeclect = onRowSeclect;
var setTotalRowCount = function setTotalRowCount(count) {
  return {
    type: actionTypes.SET_TOTAL_ROW_COUNT,
    payload: count
  };
};
exports.setTotalRowCount = setTotalRowCount;