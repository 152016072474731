"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
var _reactRouterDom = require("react-router-dom");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
/**
 * Custom hook that uses `useLocation` from `react-router-dom` to get the current location object
 * and `React.useMemo` to memoize the `URLSearchParams` object from the `search` property
 * of the location object.
 *
 *  The `URLSearchParams` object can be used to read URL query parameters.
 *
 * @returns {URLSearchParams} The `URLSearchParams` object containing the query parameters.
 */
var useQuery = function useQuery() {
  var _ref = (0, _reactRouterDom.useLocation)(),
    search = _ref.search;
  return _react.default.useMemo(function () {
    return new URLSearchParams(search);
  }, [search]);
};
var _default = useQuery;
exports.default = _default;