"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "DEFAULT_CONFIG_MANAGE_ACCESS_TABLE", {
  enumerable: true,
  get: function get() {
    return _config2.DEFAULT_CONFIG_MANAGE_ACCESS_TABLE;
  }
});
Object.defineProperty(exports, "DEFAULT_CONFIG_PENDING_REQUESTS_TABLE", {
  enumerable: true,
  get: function get() {
    return _config3.DEFAULT_CONFIG_PENDING_REQUESTS_TABLE;
  }
});
Object.defineProperty(exports, "DEFAULT_CONFIG_REVIEW_REQUESTS_TABLE", {
  enumerable: true,
  get: function get() {
    return _config4.DEFAULT_CONFIG_REVIEW_REQUESTS_TABLE;
  }
});
Object.defineProperty(exports, "DEFAULT_CONFIG_REVOKE_ACCESS_TABLE", {
  enumerable: true,
  get: function get() {
    return _config5.DEFAULT_CONFIG_REVOKE_ACCESS_TABLE;
  }
});
Object.defineProperty(exports, "DEFAULT_CONFIG_USER_DETAILS", {
  enumerable: true,
  get: function get() {
    return _config.DEFAULT_CONFIG_USER_DETAILS;
  }
});
Object.defineProperty(exports, "DEFAULT_MANAGE_ACCESS_STYLES", {
  enumerable: true,
  get: function get() {
    return _styles2.default;
  }
});
Object.defineProperty(exports, "DEFAULT_PENDING_REQUESTS_STYLES", {
  enumerable: true,
  get: function get() {
    return _styles3.default;
  }
});
Object.defineProperty(exports, "DEFAULT_REVIEW_REQUESTS_STYLES", {
  enumerable: true,
  get: function get() {
    return _styles4.default;
  }
});
Object.defineProperty(exports, "DEFAULT_REVOKE_ACCESS_STYLES", {
  enumerable: true,
  get: function get() {
    return _styles5.default;
  }
});
Object.defineProperty(exports, "DEFAULT_USER_DETAILS_STYLES", {
  enumerable: true,
  get: function get() {
    return _styles.default;
  }
});
Object.defineProperty(exports, "ManageAccessTableGenerator", {
  enumerable: true,
  get: function get() {
    return _ManageAccessTableGenerator.ManageAccessTableGenerator;
  }
});
Object.defineProperty(exports, "PendingRequestsTableGenerator", {
  enumerable: true,
  get: function get() {
    return _PendingRequestsTableGenerator.PendingRequestsTableGenerator;
  }
});
Object.defineProperty(exports, "ReviewRequestsTableGenerator", {
  enumerable: true,
  get: function get() {
    return _ReviewRequestsTableGenerator.ReviewRequestsTableGenerator;
  }
});
Object.defineProperty(exports, "RevokeAccessTableGenerator", {
  enumerable: true,
  get: function get() {
    return _RevokeAccessTableGenerator.RevokeAccessTableGenerator;
  }
});
Object.defineProperty(exports, "UserDetailsGenerator", {
  enumerable: true,
  get: function get() {
    return _UserDetailsGenerator.UserDetailsGenerator;
  }
});
var _UserDetailsGenerator = require("./UserDetails/UserDetailsGenerator");
var _config = require("./UserDetails/config");
var _styles = _interopRequireDefault(require("./UserDetails/styles"));
var _ManageAccessTableGenerator = require("./ManageAccessTable/ManageAccessTableGenerator");
var _config2 = require("./ManageAccessTable/config");
var _styles2 = _interopRequireDefault(require("./ManageAccessTable/styles"));
var _PendingRequestsTableGenerator = require("./PendingRequestsTable/PendingRequestsTableGenerator");
var _config3 = require("./PendingRequestsTable/config");
var _styles3 = _interopRequireDefault(require("./PendingRequestsTable/styles"));
var _ReviewRequestsTableGenerator = require("./ReviewRequestsTable/ReviewRequestsTableGenerator");
var _config4 = require("./ReviewRequestsTable/config");
var _styles4 = _interopRequireDefault(require("./ReviewRequestsTable/styles"));
var _RevokeAccessTableGenerator = require("./RevokeAccessTable/RevokeAccessTableGenerator");
var _config5 = require("./RevokeAccessTable/config");
var _styles5 = _interopRequireDefault(require("./RevokeAccessTable/styles"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}