"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.headerTypes = exports.dataFormatTypes = exports.cellTypes = exports.actionCellTypes = exports.LINK = exports.FORMAT_DATA_CELLS = exports.FORMAT_DATA = exports.FORMAT_BYTES = exports.DISPLAY = exports.DELETE = exports.DEFAULT = exports.ComponentTypes = exports.CUSTOM_ELEM = exports.CHECKBOX = void 0;
var CHECKBOX = 'CHECKBOX';
exports.CHECKBOX = CHECKBOX;
var DELETE = 'DELETE';
exports.DELETE = DELETE;
var CUSTOM_ELEM = 'CUSTOM_ELEM';
exports.CUSTOM_ELEM = CUSTOM_ELEM;
var LINK = 'LINK';
exports.LINK = LINK;
var DISPLAY = 'DISPLAY';
exports.DISPLAY = DISPLAY;
var DEFAULT = 'DEFAULT';
exports.DEFAULT = DEFAULT;
var FORMAT_DATA = 'FORMAT_DATA';
exports.FORMAT_DATA = FORMAT_DATA;
var FORMAT_BYTES = 'FORMAT_BYTES';
exports.FORMAT_BYTES = FORMAT_BYTES;
var cellTypes = {
  CUSTOM_ELEM: CUSTOM_ELEM,
  LINK: LINK,
  DISPLAY: DISPLAY,
  CHECKBOX: CHECKBOX,
  DELETE: DELETE,
  DEFAULT: DEFAULT,
  FORMAT_DATA: FORMAT_DATA
};
exports.cellTypes = cellTypes;
var actionCellTypes = [CHECKBOX, DELETE];
exports.actionCellTypes = actionCellTypes;
var FORMAT_DATA_CELLS = [FORMAT_BYTES];
exports.FORMAT_DATA_CELLS = FORMAT_DATA_CELLS;
var dataFormatTypes = {
  FORMAT_BYTES: 'FORMAT_BYTES'
};
exports.dataFormatTypes = dataFormatTypes;
var headerTypes = {
  CUSTOM_ELEM: CUSTOM_ELEM,
  DELETE: DELETE,
  DEFAULT: DEFAULT
};
exports.headerTypes = headerTypes;
var ComponentTypes = {
  PAGINATION: 'PAGINATION',
  MANAGE_VIEW_COLUMNS: 'MANAGE_VIEW_COLUMNS',
  DOWNLOAD_TABLE_BTN: 'DOWNLOAD_TABLE_BTN',
  CUSTOM_ELEM: 'CUSTOM_ELEM'
};
exports.ComponentTypes = ComponentTypes;